import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import "./style.css";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { Col, Row, Form, Button } from "react-bootstrap";
import changePass from "../../assets/images/changepass.svg";
import { useUser } from "../../Context/UserContext";
import Loader from "../../Components/Loader/Loader";

const PasswordChange = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);


  const { emailId } = useUser();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const noErrors = !newPasswordError && !confirmPasswordError;
    const allFilled = oldPassword !== "" && newPassword !== "" && confirmPassword !== "";
    setAllFieldsFilled(allFilled);
    setIsFormValid(noErrors && allFilled);
  }, [oldPassword, newPassword, confirmPassword, newPasswordError, confirmPasswordError]);


  const handleChangeNewPassword = (value) => {
    setNewPassword(value);
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(value)) {
      setNewPasswordError(t("changePassword.validPassword"));
    } else {
      setNewPasswordError("");
    }
  };

  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value);
    if (value !== newPassword) {
      setConfirmPasswordError(t("changePassword.passMatch"));
    } else {
      setConfirmPasswordError("");
    }
  };

  const hanleCLearFields = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setNewPasswordError("");
    setConfirmPasswordError("");
  };

  const handleField = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error(t("changePassword.passMatch"));
      return;
    }
    setIsLoading(true);
    try {
      const oldPasswordCheck = await axios.post(
        "/v1.0/api/CheckOldPasswordExists",
        {
          UserName: emailId,
          OldPassword: oldPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!oldPasswordCheck.data.successStatus) {
        if (oldPasswordCheck.data.statusCode === "7") {
          toast.error(t("changePassword.oldpassError"));
          setIsLoading(false);
        } else {
          toast.error(t("changePassword.oldPassCheck"));
        }
        return;
      }
      const response = await axios.put(
        "/v1.0/api/ChangeAppUserPassword",
        {
          UserName: emailId,
          oldPassword,
          newPassword,
          AppUserID: "39c367aa-fccf-435a-bd3d-b97189828abe",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === "1") {
        toast.success(t("changePassword.changePasswordSuccessfull"));
        handleField();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(t("changePassword.changePasswordError"), error);
    }
  };

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <Row className='password-change-container'>
        <Col>
          <div className='forSmallDevice'>
            <img src={changePass} alt='passChange' />
          </div>
        </Col>
        <Col>
          <Form onSubmit={handleSubmit} className='password-change-form'>
            <div>
              <Form.Group className='mt-2' controlId='oldPassword'>
                <Form.Label>{t("changePassword.oldPassword")}</Form.Label>
                <div className='password-input'>
                  <Form.Control className='input' type={showOldPassword ? "text" : "password"} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                  <span className='toggle-password' onClick={() => setShowOldPassword(!showOldPassword)}>
                    {showOldPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                  </span>
                </div>
              </Form.Group>

              <Form.Group className='mt-2' controlId='newPassword'>
                <Form.Label>{t("changePassword.newPassword")}</Form.Label>
                <div className='password-input'>
                  <Form.Control type={showNewPassword ? "text" : "password"} value={newPassword} onChange={(e) => handleChangeNewPassword(e.target.value)} />
                  <span className='toggle-password' onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                  </span>
                </div>
                {newPasswordError && <Form.Text className='text-danger'>{newPasswordError}</Form.Text>}
              </Form.Group>

              <Form.Group className='mt-2' controlId='confirmPassword'>
                <Form.Label>{t("changePassword.confirmPassword")}</Form.Label>
                <div className='password-input'>
                  <Form.Control type={showConfirmPassword ? "text" : "password"} value={confirmPassword} onChange={(e) => handleChangeConfirmPassword(e.target.value)} />
                  <span className='toggle-password' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                  </span>
                </div>
                {confirmPasswordError && <Form.Text className='text-danger'>{confirmPasswordError}</Form.Text>}
              </Form.Group>

              {allFieldsFilled && (
                <div className='d-flex gap-3'>
                  <Button variant='none' className='applyBtn mt-3' type='submit' disabled={!isFormValid}>
                    {t("changePassword.submit")}
                  </Button>
                  <Button variant='none' className='ImageCancelBtn mt-3' onClick={hanleCLearFields}>
                    {t("changePassword.cancel")}
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PasswordChange;
