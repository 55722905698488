import React, { useState } from "react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Dropdown, Navbar } from "react-bootstrap";
import { useUser } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import { IoSettingsOutline } from "react-icons/io5";
import logoutIcon from "../../assets/images/logout.svg";
import profileIcon from "../../assets/images/Profile.svg";
import LanguageSelector from "../Language/LanguageSelector";
import { IoIosNotificationsOutline } from "react-icons/io";
import userImg from "../../assets/images/defaultImg.svg";
import homeIcon from "../../assets/images/homeIcon.svg";
import { MdOutlineMail } from "react-icons/md";
import { LuCalendarDays } from "react-icons/lu";
import "./style.css";
import { MdOutlineManageAccounts } from "react-icons/md";

import Loader from "../Loader/Loader";

function Header() {
  const [isLoading, setIsLoading] = useState(false);
  const { profile, user, userAccess } = useUser();

  const { t } = useTranslation();
  const navigate = useNavigate();

  //token for UserData
  const token = sessionStorage.getItem("token");

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const response = await axios.put("/Account/Logout", null, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.statusCode === "1") {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
        toast.success(t("menuButtons.logoutSuccessfully"));
      }
    } catch (error) {
      toast.error(t("menuButtons.errLogoutOut"));
      setIsLoading(false);
    }
  };

  const handlepasschangeRoute = () => {
    navigate("/User-Profile");
  };
  const handleAccount = () => {
    navigate("/account-settings");
  };
  const hanldleEmailTemplates = () => {
    navigate("/create-email-template");
  };

  return (
    <Navbar className='fixed-top shadow-sm' sticky='top' style={{ display: "flex", backgroundColor: "white", alignItems: "center", justifyContent: "flex-end", gap: "15px" }}>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      {userAccess && (
        <Nav.Link className='icon-wrapper-heading'>
          {userAccess.packageName === "Trial" && userAccess.remainingDays > 0 ? (
            // Show remaining days only for the "Trial" package
            <>
            <LuCalendarDays size={22} />
            <span>{`${userAccess.remainingDays} ${t("header.daysRemaining")}`}</span>
            </>
          ) : userAccess.remainingDays <= 0 ? (
            // Show expiration message for any package when expired
            <span>
              {userAccess.packageName} {t("header.hasExpired")}
            </span>
          ) : null}
        </Nav.Link>
      )}

      <Nav.Link className='icon-wrapper'>
        <LanguageSelector />
      </Nav.Link>

      <Nav.Link className='icon-wrapper'>
        <IoIosNotificationsOutline size={24} />
        <span>{t("header.notification")}</span>
      </Nav.Link>

      {user.AccountType === "B" && user.RoleID === 1 ? (
        <>
          {userAccess.remainingDays <= 0 ? null : (
            <>
              <Nav.Link className='user_setting'>
                <Dropdown>
                  <Dropdown.Toggle variant='none' style={{ border: "none" }} id='dropdownMenuButton4'>
                    <Nav.Link className='icon-wrapper'>
                      <IoSettingsOutline size={22} />
                      <span>{t("header.settings")}</span>
                    </Nav.Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`slideDownIn animation ${document.documentElement.dir === "rtl" ? "dropdown-menu-start" : "dropdown-menu-end"}`}>
                    <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={handleAccount}>
                      {user.AccountType === "B" && user.RoleID === 1 ? (
                        <Nav.Link className='icon-wrapper' onClick={handleAccount}>
                          <MdOutlineManageAccounts size={22} />
                          <span>{t("header.accountSettings")}</span>
                        </Nav.Link>
                      ) : (
                        <></>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={hanldleEmailTemplates}>
                      <MdOutlineMail size={22} />
                      {t("header.emailTemplates")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Link>
            </>
          )}
        </>
      ) : (
        <></>
      )}

      <Nav.Link as={Link} to='/home' className='icon-wrapper'>
        <img src={homeIcon} alt='iconLoading' />
        <span>{t("header.home")}</span>
      </Nav.Link>

      <Nav.Link className='user_settings'>
        <Dropdown>
          <Dropdown.Toggle variant='none' style={{ border: "none" }} id='dropdownMenuButton4'>
            <span>
              <img
                src={profile}
                // src={`${profile}?${new Date().getTime()}`}
                alt='ProfilePhoto'
                className='accountImage'
                onError={(e) => {
                  e.target.src = userImg;
                }}
                style={{ width: "40px", height: "40px", objectFit: "cover" }}
              />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className={`slideDownIn animation ${document.documentElement.dir === "rtl" ? "dropdown-menu-start" : "dropdown-menu-end"}`}>
            <Dropdown.Item>
              <span className='user_name'>
                {t("menuButtons.Welcome")}, {user.fullName}
              </span>
            </Dropdown.Item>
            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={handlepasschangeRoute}>
              <img src={profileIcon} alt='iconLoading' />
              {t("menuButtons.profileSettings")}
            </Dropdown.Item>
            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={handleLogout}>
              <img src={logoutIcon} alt='iconLoading' />
              {t("menuButtons.logout")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Link>
    </Navbar>
  );
}

export default Header;
