import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Form, Col, Row, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import cobrandingIcon from "../../assets/images/cobrandingIcon.svg";
import logoIcon from "../../assets/images/logoIcon.svg";
import cobrandIcon from "../../assets/images/group.svg";
import dropboxIcon from "../../assets/images/Dropbox Cobranding.svg";
import googleDriveIcon from "../../assets/images/googleDriveCobranding.svg";
import oneDriveICon from "../../assets/images/oneDriveIntegrate.svg";
import localIcon from "../../assets/images/monitorCobranding.svg";
import { useUser } from "../../Context/UserContext";
import "./style.css";
import Loader from "../Loader/Loader";

function CoBrandingDetails() {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, reset, watch } = useForm();
  const { userAccess } = useUser("");
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [objectives, setObjectives] = useState("");
  const [companyPrefix, setCompanyPrefix] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [displayedImage, setDisplayedImage] = useState(logoIcon);
  const [displayedStampImage, setDisplayedStampImage] = useState(cobrandingIcon);
  const [base64Image, setBase64Image] = useState(null);
  const [base64StampImage, setBase64StampImage] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [IsGoogleDrive, setIsGoogleDrive] = useState(false);
  const [IsOneDrive, setIsOneDrive] = useState(false);
  const [IsDropbox, setIsDropbox] = useState(false);

  const token = sessionStorage.getItem("token");

  const [coBrandingData, setCoBrandingData] = useState({
    accountLogoPath: "",
    accountLogoFile: "",
    companyStampPath: "",
    companyStampFile: "",
    colorTheme: "",
    companyRefPrefix: "",
    objectives: "",
    IsGoogleDrive: false,
    IsOneDrive: false,
    IsDropbox: false,
  });

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (coBrandingData) {
      setObjectives(coBrandingData.objectives || "");
      setCompanyPrefix(coBrandingData.companyRefPrefix || "");
      setIsGoogleDrive(coBrandingData.IsGoogleDrive);
      setIsOneDrive(coBrandingData.IsOneDrive);
      setIsDropbox(coBrandingData.IsDropbox);
      setSelectedColor(coBrandingData.colorTheme || "");
      setDisplayedImage(coBrandingData.accountLogoPath && coBrandingData.accountLogoFile ? `${coBrandingData.accountLogoPath}${coBrandingData.accountLogoFile}` : logoIcon);
      setDisplayedStampImage(coBrandingData.companyStampPath && coBrandingData.companyStampFile ? `${coBrandingData.companyStampPath}${coBrandingData.companyStampFile}` : cobrandingIcon);

      reset({
        CompanyRefPrefix: coBrandingData.companyRefPrefix,
        Objectives: coBrandingData.objectives,
      });
    }
  }, [coBrandingData, reset]);

  const fetchUserProfile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/Account/UserProfile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.response.accountID) {
        setAccountId(response.data.response.accountID);
        fetchCoBrandingData(response.data.response.accountID);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (checkboxName, isChecked) => {
    switch (checkboxName) {
      case "googleDrive":
        setIsGoogleDrive(isChecked);
        break;
      case "oneDrive":
        setIsOneDrive(isChecked);
        break;
      case "dropbox":
        setIsDropbox(isChecked);
        break;
      default:
        break;
    }

    setIsFormChanged(true);
  };

  const fetchCoBrandingData = async (accountId) => {
    try {
      if (!accountId) {
        return;
      }
      setIsLoading(true);
      const response = await axios.get(`/v1.0/api/GetCoBranding?AccountID=${accountId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const { accountLogoFile, accountLogoPath, colorTheme, companyRefPrefix, objectives, companyStampPath, companyStampFile, isGoogleDrive, isOneDrive, isDropbox } = response.data.response;

        setCoBrandingData({
          companyStampFile,
          companyStampPath,
          accountLogoFile,
          accountLogoPath,
          colorTheme,
          companyRefPrefix,
          objectives,
          IsGoogleDrive: isGoogleDrive,
          IsOneDrive: isOneDrive,
          IsDropbox: isDropbox,
        });
      }
    } catch (error) {
      console.error("Error fetching cobranding data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.AccountLogoFile = base64Image;
      data.AccountLogoExtension = ".png";
      data.CompanyStampFile = base64StampImage;
      data.CompanyStampExtention = ".png";
      data.ColorTheme = selectedColor;
      data.IsGoogleDrive = IsGoogleDrive;
      data.IsOneDrive = IsOneDrive;
      data.IsDropbox = IsDropbox;

      const response = await axios.put("/Account/UpdateAccount", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(response.data.message);
        setIsFormChanged(false);
        fetchCoBrandingData(accountId);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error(t("coBranding.errWrong"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setDisplayedImage(imageUrl);

      try {
        await convertToBase64(file);
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error(error.message);
      }
    }
    setIsFormChanged(true);
  };

  const handleStampImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const stampUrl = URL.createObjectURL(file);
      setDisplayedStampImage(stampUrl);

      try {
        await convertStampToBase64(file);
      } catch (error) {
        console.error("Error converting stamp image to base64:", error);
        toast.error(error.message);
      }
    }
    setIsFormChanged(true);
  };

  const handleColorOptionClick = (color) => {
    setSelectedColor(color);
    setIsFormChanged(true);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.name.endsWith(".png") || file.name.endsWith(".jpeg") || file.name.endsWith(".jpg")) {
        reader.onload = () => {
          let base64String = reader.result?.split(",")[1];
          base64String = base64String.replace(/["',:;]/g, "");
          setBase64Image(base64String);
          resolve(base64String);
        };
      } else {
        reject(new Error("Invalid file format. Please upload a PNG or JPG file."));
      }

      reader.onerror = (error) => reject(error);
    });
  };

  const convertStampToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.name.endsWith(".png") || file.name.endsWith(".jpeg") || file.name.endsWith(".jpg")) {
        reader.onload = () => {
          let base64String = reader.result?.split(",")[1];
          base64String = base64String.replace(/["',:;]/g, "");
          setBase64StampImage(base64String);
          resolve(base64String);
        };
      } else {
        reject(new Error("Invalid file format. Please upload a PNG or JPG file."));
      }

      reader.onerror = (error) => reject(error);
    });
  };

  const handleObjectivesChange = (e) => {
    setObjectives(e.target.value);
    setValue("Objectives", e.target.value);
    setIsFormChanged(true);
  };

  const handleCompanyPrefixChange = (e) => {
    setCompanyPrefix(e.target.value);
    setValue("CompanyRefPrefix", e.target.value);
    setIsFormChanged(true);
  };

  const handleCancelChanges = () => {
    setIsFormChanged(false);
    fetchCoBrandingData(accountId);
  };

  return (
    <>
      <Accordion defaultActiveKey='0'>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>
            <div className='d-flex align-items-center gap-3'>
              <img src={cobrandIcon} alt='IconLoading' />
              <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("coBranding.coBranding")}</label>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className='main-container'>
              {isLoading && (
                <div className='loader-overlay'>
                  <Loader />
                </div>
              )}
              <div className='main-container-form'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={6}>
                      <div className='profile-Form'>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                          {displayedImage && (
                            <img
                              src={displayedImage}
                              alt='Selected'
                              className='Imageshown'
                              onError={(e) => {
                                e.target.src = logoIcon;
                              }}
                            />
                          )}

                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <Form.Label style={{ fontSize: "18px", fontWeight: "600" }}>{t("coBranding.CompanyLogo")}</Form.Label>
                            <Form.Label>{t("coBranding.uploadCompanyLogo")}</Form.Label>
                          </div>
                        </div>
                        <Form.Label className='uploadProfilebtn' htmlFor='accountLogoImg'>
                          <Form.Control id='accountLogoImg' style={{ display: "none" }} name='accountLogoImg' accept='.jpg, .jpeg, .png' type='file' onChange={handleImageChange} />
                          {t("coBranding.uploadNewLogo")}
                        </Form.Label>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className='profile-Form'>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                          {displayedStampImage && (
                            <img
                              src={displayedStampImage}
                              alt='Selected'
                              className='Imageshown'
                              onError={(e) => {
                                e.target.src = cobrandingIcon;
                              }}
                            />
                          )}

                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <Form.Label style={{ fontSize: "18px", fontWeight: "600" }}>{t("coBranding.CompanyStamp")}</Form.Label>
                            <Form.Label>{t("coBranding.uploadCompanysStamp")}</Form.Label>
                          </div>
                        </div>

                        <Form.Label className='uploadProfilebtn' htmlFor='CompanyStampFile'>
                          <Form.Control id='CompanyStampFile' style={{ display: "none" }} name='CompanyStampFile' accept='.jpg, .jpeg, .png' type='file' onChange={handleStampImageChange} />
                          {t("coBranding.uploadStamp")}
                        </Form.Label>
                      </div>
                    </Col>

                    <Col md={6}>
                      <Col>
                        <div style={{ marginTop: "12px" }}>
                          <Form.Label>{t("coBranding.companyName")}</Form.Label>
                        </div>
                        <Form.Control {...register("CompanyRefPrefix")} onChange={handleCompanyPrefixChange} value={watch("CompanyRefPrefix")} placeholder={t("coBranding.enterCompanyName")} />
                      </Col>

                      <Col className='my-3'>
                        <label>{t("coBranding.FileSources")}</label>

                        <Row>
                          <Col md={4}>
                            <div className={`custom-card active`}>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                  <img src={localIcon} alt='local icon' />
                                  <span className='mx-2'>{t("coBranding.System")}</span>
                                </div>
                                <FaCheck className='checkmarkSource' />
                              </div>
                            </div>
                          </Col>

                          {userAccess.googDrive === true && (
                            <Col md={4}>
                              <div className={`custom-card ${IsGoogleDrive ? "active" : ""}`} onClick={() => handleCheckboxChange("googleDrive", !IsGoogleDrive)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='d-flex align-items-center'>
                                    <img src={googleDriveIcon} alt='google icon' />
                                    <span className='mx-2'>{t("coBranding.GoogleDrive")}</span>
                                  </div>
                                  {IsGoogleDrive && <FaCheck className='checkmarkSource' />}
                                </div>
                              </div>
                            </Col>
                          )}

                          {userAccess.dropbox === true && (
                            <Col md={4}>
                              <div className={`custom-card ${IsDropbox ? "active" : ""}`} onClick={() => handleCheckboxChange("dropbox", !IsDropbox)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='d-flex align-items-center'>
                                    <img src={dropboxIcon} alt='dropbox icon' />
                                    <span className='mx-2'>{t("coBranding.Dropbox")}</span>
                                  </div>
                                  {IsDropbox && <FaCheck className='checkmarkSource' />}
                                </div>
                              </div>
                            </Col>
                          )}
                          
                          {userAccess.oneDrive === true && (
                            <Col md={4}>
                              <div className={`custom-card ${IsOneDrive ? "active" : ""}`} onClick={() => handleCheckboxChange("oneDrive", !IsOneDrive)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='d-flex align-items-center'>
                                    <img src={oneDriveICon} alt='google icon' />
                                    <span className='mx-2'>{t("coBranding.OneDrive")}</span>
                                  </div>
                                  {IsOneDrive && <FaCheck className='checkmarkSource' />}
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Col>

                    <Col md={6}>
                      <div style={{ marginTop: "12px" }}>
                        <Form.Label htmlFor='objectives'>{t("coBranding.description")}</Form.Label>
                      </div>
                      <Form.Control
                        as='textarea'
                        rows={5}
                        id='objectives'
                        {...register("Objectives")}
                        onChange={handleObjectivesChange}
                        value={watch("Objectives")}
                        placeholder={t("coBranding.enterDescription")}
                      />
                    </Col>

                    <Col md={6}>
                      <Form.Label className='my-3'>{t("coBranding.SideColor")}</Form.Label>
                      <div className='color-options'>
                        <div className='color-option' style={{ backgroundColor: "#0979A9" }} onClick={() => handleColorOptionClick("#0979A9")}>
                          {selectedColor === "#0979A9" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#9409A9" }} onClick={() => handleColorOptionClick("#9409A9")}>
                          {selectedColor === "#9409A9" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#092DA9" }} onClick={() => handleColorOptionClick("#092DA9")}>
                          {selectedColor === "#092DA9" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#A90969" }} onClick={() => handleColorOptionClick("#A90969")}>
                          {selectedColor === "#A90969" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#A90909" }} onClick={() => handleColorOptionClick("#A90909")}>
                          {selectedColor === "#A90909" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#A94C09" }} onClick={() => handleColorOptionClick("#A94C09")}>
                          {selectedColor === "#A94C09" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#2E7404" }} onClick={() => handleColorOptionClick("#2E7404")}>
                          {selectedColor === "#2E7404" && <FaCheck className='checkmark' />}
                        </div>
                      </div>
                      <div className='color-labels'>
                        <div>#0979A9</div>
                        <div>#9409A9</div>
                        <div>#092DA9</div>
                        <div>#A90969</div>
                        <div>#A90909</div>
                        <div>#A94C09</div>
                        <div>#2E7404</div>
                      </div>
                    </Col>

                    <div className='d-flex gap-2 justify-content-start mt-5'>
                      {isFormChanged && (
                        <>
                          <Button variant='none' className='ImageDoneBtn' onClick={handleSubmit(onSubmit)}>
                            {t("userProfile.update")}
                          </Button>
                          <Button variant='none' className='ImageCancelBtn' onClick={handleCancelChanges}>
                            {t("coBranding.cancel")}
                          </Button>
                        </>
                      )}
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default CoBrandingDetails;
