import React, { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../Api/api";
import { useUser } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import rectangleBox from "../../assets/images/rectangle.png";
import Loader from "../Loader/Loader";

const CreateEmailTemplate = () => {
  const { t } = useTranslation();
  const [emailSubject, setEmailSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [emailBody, setEmailBody] = useState("");
  const [templateType, setTemplateType] = useState("");
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const token = sessionStorage.getItem("token");

  const emailTemplateID = location.state?.template?.emailTemplateID;

  useEffect(() => {
    if (location.state?.template) {
      const { subject, templateType, body } = location.state.template;
      setEmailSubject(subject);
      setTemplateType(templateType);
      setEmailBody(body);
    }
  }, [location.state]);

  const handleTemplateTypeChange = (e) => {
    const selectedType = e.target.value;
    setTemplateType(selectedType);

    if (selectedType === "Sign Document") {
      setEmailBody(`<p>${t("emailTemplates.emailSignDocument")}</p>`);
    } else if (selectedType === "Password Reset") {
      setEmailBody(`<p>${t("emailTemplates.resetPassword")}</p>`);
    } else {
      setEmailBody("");
    }
  };

  const handleCancel = () => {
    setEmailSubject("");
    setTemplateType("");
    setEmailBody("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailSubject || !emailBody || !templateType) {
      toast.error(t("emailTemplates.allFields"));
      return;
    }

    const requestBody = {
      EmailTemplateID: emailTemplateID || null,
      AccountID: user.AccountID,
      TemplateType: templateType,
      Subject: emailSubject,
      Body: emailBody,
    };
    setIsLoading(true);
    try {
      const response = await axios.post("/v1.0/api/UpsertAccountEmailTemplate", requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusCode === "1") {
        toast.success(emailTemplateID ? t("emailTemplates.templateUpdated") : t("emailTemplates.templateCreated"));
        navigate("/email-templates");
      } else if (response.data.statusCode === "10") {
        toast.info(t("emailTemplates.emailTemplatesExits"));
      }
    } catch (error) {
      toast.info(t("emailTemplates.emailError"));
      console.error("Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isEmailTemplateValid = emailSubject && emailBody && templateType;

  return (
    <div className='container mt-3'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <Row className='my-3 gap-4 justify-content-center'>
        <Col md={3}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img src={rectangleBox} alt='Rectangle' />
            <div className='MACACIIMAGE'>
              <div>
                <label>{t("emailTemplates.letCreateEmail")}</label>
                <br />
                <label>{t("macAi.seeHistory")}</label>
                <Link className='mx-1' to='/email-templates'>
                  <label className='AidocumentBtn'>{t("macAi.gotoList")}</label>
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit}>
        <div className='userProfile-form'>
          <Row>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  {t("emailTemplates.emailSubject")} <span className='required mx-1'>*</span>
                </Form.Label>
                <Form.Control type='text' value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} placeholder='Enter email subject' required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  {t("emailTemplates.templateType")}
                  <span className='required mx-1'>*</span>
                </Form.Label>
                <Form.Select value={templateType} onChange={handleTemplateTypeChange} required>
                  <option value='' disabled>
                    {t("emailTemplates.SelectType")}
                  </option>
                  <option value='Declined'>{t("emailTemplates.Declined")}</option>
                  <option value='Completed'>{t("emailTemplates.Completed")}</option>
                  <option value='Password Reset'>{t("emailTemplates.PasswordReset")}</option>
                  <option value='Create team'>{t("emailTemplates.Createteam")}</option>
                  <option value='Sign Document'>{t("emailTemplates.SigningDocument")}</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className='userProfile-form mt-3'>
          <Form.Group className='mb-3'>
            <Form.Label>
              {t("emailTemplates.emailBody")}
              <span className='required mx-1'>*</span>
            </Form.Label>
            <JoditEditor
              ref={editorRef}
              value={emailBody}
              config={{
                height: 300,
                placeholder: t("macAi.enterText"),
                readonly: false,
                toolbarSticky: false,
                buttons: ["bold", "italic", "underline", "|", "ul", "ol", "|", "align", "|", "undo", "redo", "|", "link", "|", "hr", "|"],
                toolbar: true,
                speechRecognize: false,
                source: false,
                fullsize: false,
                print: false,
                about: false,
              }}
              tabIndex={1}
              onBlur={(newContent) => setEmailBody(newContent)}
            />
          </Form.Group>
        </div>

        {isEmailTemplateValid && (
          <div className='d-flex gap-3 my-3'>
            <Button variant='none' className='ImageDoneBtn' type='submit'>
              {emailTemplateID ? t("emailTemplates.updateTemplate") : t("emailTemplates.createTemplate")}
            </Button>
            <Button variant='none' className='ImageCancelBtn' onClick={handleCancel}>
              {t("userProfile.cancel")}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default CreateEmailTemplate;
