import React, { useState } from "react";
import { Chips } from "primereact/chips";
import { Button, Row, Col } from "react-bootstrap";
import axios from "../../../Api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./style.css";

const CCEmail = () => {
  const { t } = useTranslation();

  const [items, setItems] = useState([]);
  const [toValue, setToValue] = useState([]);
  const [error, setError] = useState(null);

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);
      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const handleButtonClick = async () => {
    const token = sessionStorage.getItem("token");
    const storedRegisterSignID = localStorage.getItem("registerSignID");

    try {
      const emailsString = items.join(",");
      const toString = toValue.join(",");
      const response = await axios.put(
        "/v1.0/api/CopyTo",
        {
          RegisterSignID: storedRegisterSignID,
          emailTo: toString,
          CopyTo: emailsString,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("uploadDocFlow.SuccessUpdated"));
      }
    } catch (error) {
      toast.error(t("uploadDocFlow.errFailed"));
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div>
            <div>
              <label className='mb-2'>{t("uploadDocFlow.emailAdd")}</label>

              <Chips value={items} onChange={(e) => setItems(e.value)} className={"form-control" + (error ? " has-error" : "")} placeholder={t("uploadDocFlow.enterEmail")} />
              {error && <p className='error'>{error}</p>}
            </div>

            {error && <p className='error'>{error}</p>}
          </div>

          {items.length > 0 && (
            <Button variant='none' className='Continue-btn mt-3' style={{ fontWeight: "400" }} onClick={handleButtonClick}>
              {t("uploadDocFlow.AddtoCC")}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CCEmail;
