import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import userImg from "../../assets/images/defaultImg.svg";
import SignImg from "../../assets/images/signatory.svg";
import { Button, Col, Row, Accordion, Form, Modal } from "react-bootstrap";
import "./style.css";
import { useTranslation } from "react-i18next";
import userEditIcon from "../../assets/images/user-edit.svg";
import lockIcon from "../../assets/images/Lock.svg";
import PasswordChange from "../PasswordChange/PasswordChange";
import Delegation from "../../Components/Delegation/Delegation";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import Loader from "../../Components/Loader/Loader";

function UserProfile() {
  const { t } = useTranslation();
  const [fullNameInput, setFullNameInput] = useState("");
  const [mobileNumberInput, setMobileNumberInput] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { userAccess } = useUser("");
  const { handleSubmit } = useForm();
  const [userRoleID, setUserRoleID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [displayedImage, setDisplayedImage] = useState(userImg);
  const [displaySignatureImg, setdisplaySignatureImg] = useState(SignImg);
  const [base64Image, setBase64Image] = useState(null);
  const [base64Signature, setBase64Signature] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [isSignatureSelected, setIsSignatureSelected] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [fetchCountryCodes, setFetchCountryCodes] = useState([]);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    fullName: "",
    emailID: "",
    userPhotoPath: "",
    mySignaturePath: "",
    mobileNumber: "",
  });

  const token = sessionStorage.getItem("token");

  // Fetching country codes for the dropdown
  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get("/v1.0/api/GetLookups?LookupType=countryCode", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.successStatus) {
          setFetchCountryCodes(response.data.response);
        }
      } catch (error) {
        console.error("Failed to fetch country codes:", error);
      }
    };

    fetchCountryCodes();
  }, [token]);

  //Update Account API
  const onSubmit = async () => {
    const data = {
      UserPhoto: base64Image,
      UserPhotoExtention: ".png",
      MySignatureFile: base64Signature,
      MySignatureExtention: ".png",
    };

    setIsLoading(true);
    try {
      const response = await axios.put("/Account/UpdateProfile", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusCode === "1") {
        toast.success(t("userProfile.profileUpdate"));

        await fetchUserProfile();

        handleCancel();
      }
    } catch (error) {
      toast.error(t("userProfile.errProfileUpdate"));
    } finally {
      setIsLoading(false);
    }
  };

  //UserProfile API for Fetching Profile Path
  const fetchUserProfile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/Account/UserProfile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusCode === "1") {
        const { fullName, emailID, userPhotoPath, mySignaturePath, roleID, userPhoto, mySignatureFile, mobileNumber, countryCode } = response.data.response;
        setUserData({
          fullName,
          emailID,
          userPhotoPath,
          mySignaturePath,
          mobileNumber,
          countryCode,
        });
        setDisplayedImage(userPhoto ? userPhotoPath : userImg);
        setdisplaySignatureImg(mySignatureFile ? mySignaturePath : SignImg);
        setUserRoleID(roleID);
        setFullNameInput(fullName); // Set the initial value of the full name input
        setMobileNumberInput(mobileNumber); // Set the initial value of the mobile number input
        setCountryCode(countryCode);
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === "2" || error?.response?.data?.statusCode === "4") {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info("session expired");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [token]);

  const handleNameSave = async () => {
    setIsLoading(true);
    try {
      const roleIdString = String(userRoleID);

      const response = await axios.put(
        "/v1.0/api/UpdateAppUser",
        { FullName: fullNameInput, EmailID: userData.emailID, RoleID: roleIdString, UserName: userData.emailID, MobileNumber: mobileNumberInput, CountryCode: countryCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === "1") {
        toast.success(t("userProfile.nameUpdate"));
        fetchUserProfile();
        setUserData({ ...userData, fullName: fullNameInput, mobileNumber: mobileNumberInput, CountryCode: countryCode });
        setShowSaveButton(false);
      } else {
        toast.error(t("userProfile.errUpateName"));
      }
    } catch (error) {
      toast.error(t("userProfile.errUpateName"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setDisplayedImage(imageUrl);
      try {
        await convertToBase64(file);
        setIsImageSelected(true);
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error(t("userProfile.failedtoChange"));
      }
    }
  };

  const handleStampImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const stampUrl = URL.createObjectURL(file);
      setdisplaySignatureImg(stampUrl);

      try {
        await convertStampToBase64(file);
        setIsSignatureSelected(true);
      } catch (error) {
        console.error("Error converting stamp image to base64:", error);
        toast.error(t("userProfile.failedtoChange"));
      }
    }
  };

  const handleDoneClick = async () => {
    await onSubmit();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.name.endsWith(".png") || file.name.endsWith(".jpeg") || file.name.endsWith(".jpg")) {
        reader.onload = () => {
          let base64String = reader.result?.split(",")[1];
          base64String = base64String.replace(/["',:;]/g, "");
          setBase64Image(base64String);
          resolve(base64String);
        };
      } else {
        reject(new Error("Invalid file format. Please upload a PNG or JPG file."));
      }

      reader.onerror = (error) => reject(error);
    });
  };

  const convertStampToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.name.endsWith(".png") || file.name.endsWith(".jpeg") || file.name.endsWith(".jpg")) {
        reader.onload = () => {
          let base64String = reader.result?.split(",")[1];
          base64String = base64String.replace(/["',:;]/g, "");
          setBase64Signature(base64String);
          resolve(base64String);
        };
      } else {
        reject(new Error("Invalid file format. Please upload a PNG or JPG file."));
      }

      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => {
    setDisplayedImage(userData.userPhotoPath ? userData.userPhotoPath : userImg);
    setdisplaySignatureImg(userData.mySignaturePath ? userData.mySignaturePath : SignImg);
    setIsImageSelected(false);
    setIsSignatureSelected(false);
    setBase64Image(null);
    setBase64Signature(null);
    setFullNameInput(userData.fullName);
    setMobileNumberInput(userData.mobileNumber);
    setCountryCode(userData.countryCode);
  };

  const handleNameCancel = () => {
    setFullNameInput(userData.fullName);
    setMobileNumberInput(userData.mobileNumber);
    setCountryCode(userData.countryCode);
    setShowSaveButton(false);
  };

  const closeImageModal = () => setShowImageModal(false); // Close profile image modal function
  // Open the profile image modal only if a userPhotoPath exists
  const openImageModal = () => {
  if (userData.userPhotoPath && displayedImage !== userImg) {
    setShowImageModal(true);
  }
  };

  const closeSignatureModal = () => setShowSignatureModal(false); // Close signature image modal function
  // Open the signature image modal only if mySignaturePath exists
  const openSignatureModal = () => {
  if (userData.mySignaturePath && displaySignatureImg !== SignImg) {
    setShowSignatureModal(true);
  }
  };

  return (
    <div className='container my-3'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='userProfile-form container mb-2'>
        <div className='d-flex align-items-center gap-3 mb-3'>
          <img src={userEditIcon} alt='Selected' />
          <div>
            <label style={{ fontSize: "20px", fontWeight: "600" }}>{t("userProfile.desprofile")}</label>
          </div>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <div className='profile-Form'>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  {displayedImage && (
                    <img
                      src={displayedImage}
                      alt='Selected'
                      className='Imageshown'
                      onClick={openImageModal} // Open profile image modal on image click
                      style={{ cursor: "pointer" }} // Add cursor pointer to indicate clickability
                      onError={(e) => {
                        e.target.src = userImg;
                      }}
                    />
                  )}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Label style={{ fontSize: "18px", fontWeight: "600" }}>{userData.fullName || "Full Name"}</Form.Label>
                    <Form.Label>{t("userProfile.uploadProfileImage")}</Form.Label>
                  </div>
                </div>
                <Form.Label className='uploadProfilebtn' htmlFor='accountLogoImg'>
                  <Form.Control id='accountLogoImg' style={{ display: "none" }} name='accountLogoImg' accept='.jpg, .jpeg, .png' type='file' onChange={handleImageChange} />
                  {t("userProfile.uploadProfile")}
                </Form.Label>
              </div>
            </Col>

            <Col md={6}>
              <div className='profile-Form'>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  {displaySignatureImg && (
                    <img
                      src={displaySignatureImg}
                      alt='Selected'
                      className='Imageshown'
                      onClick={openSignatureModal} // Open signature image modal on image click
                      style={{ cursor: "pointer" }} // Add cursor pointer to indicate clickability
                      onError={(e) => {
                        e.target.src = SignImg;
                      }}
                    />
                  )}

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Label style={{ fontSize: "18px", fontWeight: "600" }}>{t("userProfile.MySignature")}</Form.Label>
                    <Form.Label>{t("userProfile.uploadSignatureImage")}</Form.Label>
                  </div>
                </div>
                <Form.Label className='uploadProfilebtn' htmlFor='CompanyStampFile'>
                  {t("userProfile.uploadSignature")}
                  <Form.Control id='CompanyStampFile' style={{ display: "none" }} name='CompanyStampFile' accept='.jpg, .jpeg, .png' type='file' onChange={handleStampImageChange} />
                </Form.Label>
              </div>
            </Col>
          </Row>

          <div className='d-flex gap-2 justify-content-start my-2'>
            {isImageSelected || isSignatureSelected ? (
              <>
                <Button variant='none' onClick={handleDoneClick} className='ImageDoneBtn'>
                  {t("userProfile.save")}
                </Button>
                <Button variant='none' onClick={handleCancel} className='ImageCancelBtn'>
                  {t("userProfile.cancel")}
                </Button>
              </>
            ) : null}
          </div>

          <Row>
            <Col md={4}>
              <Form.Group controlId='fullName'>
                <Form.Label>{t("logScreens.name")}</Form.Label>
                <Form.Control
                  type='text'
                  value={fullNameInput}
                  onChange={(e) => {
                    setFullNameInput(e.target.value);
                    setShowSaveButton(true);
                  }}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Label>{t("logScreens.mobileNumber")}</Form.Label>
              <div className='input-group'>
                <Form.Select
                  as='select'
                  value={countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                    setShowSaveButton(true);
                  }}
                  className={`form-control`}
                  style={{ width: "20%" }}
                >
                  <option value='' disabled>
                    {t("logScreens.Codes")}
                  </option>
                  {fetchCountryCodes.map((country) => (
                    <option key={country.lookupID} value={country.lookupID}>
                      {country.lookupID}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control
                  type='text'
                  value={mobileNumberInput}
                  onChange={(e) => {
                    setMobileNumberInput(e.target.value);
                    setShowSaveButton(true);
                  }}
                  placeholder={t("logScreens.mobileNumber")}
                  className={`form-control`}
                  style={{ width: "calc(80% - 40px)" }}
                />
              </div>
            </Col>
            <Col md={4}>
              <Form.Group controlId='emailID'>
                <Form.Label>{t("userProfile.emailAdd")}</Form.Label>
                <Form.Control type='text' value={userData.emailID} disabled />
              </Form.Group>
            </Col>
          </Row>
        </Form>

        {showSaveButton && (
          <div className='d-flex gap-3 mt-3'>
            <Button variant='none' onClick={handleNameSave} className='ImageDoneBtn'>
              {t("userProfile.update")}
            </Button>
            <Button variant='none' onClick={handleNameCancel} className='ImageCancelBtn'>
              {t("userProfile.cancel")}
            </Button>
          </div>
        )}
      </div>
      {userAccess.delegation === true ? (
        <>
          <Delegation />
        </>
      ) : (
        <></>
      )}

      <div className='container p-0'>
        <Accordion defaultActiveKey='0'>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>
              <div className='d-flex align-items-center gap-3'>
                <img src={lockIcon} alt='IconLoading' />
                <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("userProfile.changePassword")}</label>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {/* Change password Component */}
              <PasswordChange />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      {/* Modal for displaying the profile image */}
      <Modal show={showImageModal} onHide={closeImageModal} centered dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("userProfile.desprofile")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center'>
          <img src={displayedImage} alt='Profile' style={{ maxWidth: "100%", maxHeight: "400px" }} />
        </Modal.Body>
      </Modal>

      {/* Modal for displaying the signature image */}
      <Modal show={showSignatureModal} onHide={closeSignatureModal} centered dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("userProfile.MySignature")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center'>
          <img src={displaySignatureImg} alt='Signature' style={{ maxWidth: "100%", maxHeight: "400px" }} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UserProfile;
