import React, { useEffect, useState } from "react";
import "./Signatories.css";
import { Form, Button, Row, Col, Dropdown, Accordion, Modal } from "react-bootstrap";
import moreIcon from "../../../assets/images/moreIcon.png";
import deleteSign from "../../../assets/images/deleteSign.svg";
import dragIcon from "../../../assets/images/dragIcon.png";
import { useTranslation } from "react-i18next";
import axios from "../../../Api/api";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../Context/UserContext";
import { IoIosAddCircleOutline } from "react-icons/io";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoKeyOutline } from "react-icons/io5";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { BsVectorPen } from "react-icons/bs";
import { TfiWorld } from "react-icons/tfi";

const Signatories = ({ setActiveStep, setCompletedSteps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [signatureTypes, setSignatureTypes] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [signatoryColor, setSignatoryColor] = useState([]);
  const [verificationMethods, setVerificationMethods] = useState([]);
  const [languageTypes, setLanguageTypes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [showSignOrder, setShowSignOrder] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newIndex, setNewIndex] = useState("");
  const [errors, setErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteSignatoryID, setDeleteSignatoryID] = useState(null);
  const [teamMemberEmails, setTeamMemberEmails] = useState([]);
  const [showDropdowns, setShowDropdowns] = useState(() => JSON.parse(localStorage.getItem("showDropdowns")) || {});
  const [showVerificationMethods, setShowVerificationMethods] = useState(() => JSON.parse(localStorage.getItem("showVerificationMethods")) || {});
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [teamMemberNames, setTeamMemberNames] = useState([]);
  const [nameToEmailMap, setNameToEmailMap] = useState({});


  const storedRegisterSignID = localStorage.getItem("registerSignID");
  const token = sessionStorage.getItem("token");

  const initialFormState = {
    name: "",
    notificationType: "Email",
    email: "",
    countryCode: "",
    mobile: "",
    company: "",
    signatureType: "Needs To Sign",
    signatureColor: "#E84600",
    signPage: "FirstPage",
    signPosition: "TopLeft",
    signOrder: 1,
    isMobile: true,
    expiryDate: "",
    isExpiring: false,
    isPasswordProtected: false,
    languageType: "English",
    verificationType: "None",
    signatoriesID: null,
    showEmail: true,
    showMobile: false,
  };

  const [forms, setForms] = useState([initialFormState]);

  useEffect(() => {
    localStorage.setItem("showDropdowns", JSON.stringify(showDropdowns));
  }, [showDropdowns]);

  useEffect(() => {
    localStorage.setItem("showVerificationMethods", JSON.stringify(showVerificationMethods));
  }, [showVerificationMethods]);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=signatureType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const signatureTypeOptions = response.data.response.map((item) => item.name);
          setSignatureTypes(signatureTypeOptions);
        }
      })
      .catch((error) => handleAuthError(error));
  }, [token]);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=NotificationType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const notificationTypeOptions = response.data.response.map((item) => item.name);
          setNotificationTypes(notificationTypeOptions);
        }
      })
      .catch((error) => handleAuthError(error));
  }, [token]);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=SignColor", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const formColors = response.data.response.map((color) => color.name);
          setSignatoryColor(formColors);
        }
      })
      .catch((error) => handleAuthError(error));
  }, [token]);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=VerificationType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const verification = response.data.response.map((method) => method.lookupID);
          setVerificationMethods(verification);
        }
      })
      .catch((error) => handleAuthError(error));
  }, [token]);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=LanguageType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const language = response.data.response.map((language) => language.name);
          setLanguageTypes(language);
        }
      })
      .catch((error) => handleAuthError(error));
  }, [token]);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=countryCode", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const countryCodes = response.data.response.map((code) => ({
            lookupID: code.lookupID,
            name: code.name,
          }));
          setCountryCodes(countryCodes);
        }
      })
      .catch((error) => handleAuthError(error));
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/v1.0/api/GetRegisterSignData?RegisterSignID=${storedRegisterSignID}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (response.data.successStatus) {
          const signatories = response.data.response.signatories;
          const serialCheck = response.data.response.isSerial;
          setShowSignOrder(serialCheck);

          if (signatories && signatories.length > 0) {
            const initializedForms = signatories.map((signatory) => {
              const { showEmail, showMobile } = determineFieldVisibility(signatory.notificationType, signatory.verificationType, signatory.email);

              return {
                ...signatory,
                showEmail,
                showMobile,
              };
            });

            setForms(initializedForms);
          } else {
            setForms([initialFormState]);
          }
        } else {
          setForms([initialFormState]);
        }
      } catch (error) {
        handleAuthError(error);
        setForms([initialFormState]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [storedRegisterSignID, token]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        if (!user.AccountID) {
          return;
        }
        setIsLoading(true);
        const response = await axios.get(`/v1.0/api/GetTeam?AccountID=${user.AccountID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const emails = response.data.response.map((member) => member.emailID);
        const names = response.data.response.map((member) => member.fullName);

        setTeamMemberEmails(emails);
        setTeamMemberNames(names);

        // Create a mapping between names and emails
        const nameToEmailMap = {};
        response.data.response.forEach((member) => {
          nameToEmailMap[member.fullName] = member.emailID;
        });

        setNameToEmailMap(nameToEmailMap);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamMembers();
  }, [user.AccountID, token]);

  const handleAuthError = (error) => {
    if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
      sessionStorage.removeItem("token");
      localStorage.clear();
      setIsLoading(false);
      navigate("/");
      toast.info(t("uploadDocFlow.sessionExpire"), {
        position: "top-right",
      });
    }
  };

  const validateForm = () => {
    let hasErrors = false;
    const emailSet = new Set();
    const newErrors = forms.map((form, index) => {
      const formErrors = {};

      // Validate Name
      if (!form.name) {
        formErrors.name = t("uploadDocFlow.requiredName");
        hasErrors = true;
      }

      // Validate Notification Type and Verification Method Logic
      if (form.showEmail && (!form.email || !/\S+@\S+\.\S+/.test(form.email))) {
        formErrors.email = !form.email ? t("uploadDocFlow.emailRequired") : t("uploadDocFlow.validEmail");
        hasErrors = true;
      }

      if (form.showEmail && emailSet.has(form.email)) {
        formErrors.email = t("uploadDocFlow.alreadyEmailUsed");
        hasErrors = true;
      } else if (form.showEmail) {
        emailSet.add(form.email);
      }

      // Validate Mobile Number if it's visible
      if (form.showMobile && (!form.mobile || !form.countryCode)) {
        formErrors.mobile = t("uploadDocFlow.phoneRequired");
        hasErrors = true;
      }

      return formErrors;
    });

    setErrors(newErrors);
    return !hasErrors;
  };

  const determineFieldVisibility = (notificationType, verificationType, email) => {
    let showEmail = false;
    let showMobile = false;

    if (email) {
      showEmail = true;
    }

    if (notificationType === "Email") {
      if (verificationType === "SMS" || verificationType === "WhatsApp") {
        showEmail = true;
        showMobile = true;
      } else {
        showEmail = true;
        showMobile = false;
      }
    } else if (notificationType === "SMS" || notificationType === "WhatsApp") {
      if (verificationType === "Email") {
        showEmail = true;
        showMobile = true;
      } else {
        showEmail = false;
        showMobile = true;
      }
    }

    return { showEmail, showMobile };
  };

  const handleInputChange = (index, field, value) => {
    const updatedForms = [...forms];
    updatedForms[index] = { ...updatedForms[index], [field]: value };


  if (field === "name") {
    // Autofill the email based on the selected name
    const correspondingEmail = nameToEmailMap[value];
    updatedForms[index].email = correspondingEmail || ""; // Set the email if found, otherwise leave it empty
  }

    if (field === "notificationType" || field === "verificationType") {
      const { showEmail, showMobile } = determineFieldVisibility(updatedForms[index].notificationType, updatedForms[index].verificationType);
      updatedForms[index].showEmail = showEmail;
      updatedForms[index].showMobile = showMobile;
    }

    setForms(updatedForms);
    clearError(index, field);
  };

  const handleToggleDropdown = (index) => {
    setShowDropdowns((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggleVerificationMethod = (index) => {
    setShowVerificationMethods((prevState) => {
      const newState = { ...prevState };
      newState[index] = !prevState[index];
      return newState;
    });

    setShowDropdowns((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const handleDeleteSignatory = async () => {
    if (deleteSignatoryID) {
      try {
        setIsLoading(true);
        const response = await axios.put(
          "/v1.0/api/DeleteSignatory",
          {
            SignatoriesID: deleteSignatoryID,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        if (response.status === 200) {
          const updatedForms = [...forms];
          updatedForms.splice(deleteIndex, 1);
          setForms(updatedForms);
          setShowModal(false);
        }
      } catch (error) {
        setIsLoading(false);
        handleAuthError(error);
        toast.error(t("uploadDocFlow.ErrDeletSignatory"));
      } finally {
        setIsLoading(false);
      }
    } else {
      const updatedForms = [...forms];
      updatedForms.splice(deleteIndex, 1);
      setForms(updatedForms);
      setShowModal(false);
    }
  };

  const clearError = (index, field) => {
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (newErrors[index]) {
        delete newErrors[index][field];
        if (Object.keys(newErrors[index]).length === 0) {
          newErrors[index] = null;
        }
      }
      return newErrors;
    });
  };

  const handleShowModal = (index, signatoryID) => {
    setDeleteIndex(index);
    setDeleteSignatoryID(signatoryID);
    setShowModal(true);
  };

  const handleAddRecipient = () => {
    const nextColorIndex = forms.length % signatoryColor.length;
    const newForm = {
      ...initialFormState,
      signatureColor: signatoryColor[nextColorIndex],
    };
    setForms([...forms, newForm]);
  };

  const handleAddYourself = () => {
    const nextColorIndex = forms.length % signatoryColor.length;
    const newForm = {
      ...initialFormState,
      name: user.fullName,
      email: user.email,
      countryCode: user.mobileNumber ? user.mobileNumber.split("-")[0] : "",
      mobile: user.mobileNumber ? user.mobileNumber.split("-")[1] : "",
      company: "",
      signatureColor: signatoryColor[nextColorIndex],
    };
    setForms([...forms, newForm]);
  };

  const workflow = async (name, number, activeStep, completedSteps) => {
    const StepData = {
      SignWorkFlowActivityName: name,
      SignWorkFlowActivityRef: number,
      SignWorkFlowActivityClass: "RED",
      RegisterSignID: storedRegisterSignID,
      IsMobile: false,
    };

    try {
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateWorkFlowActivity", StepData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setIsLoading(false);

      if (response.data.successStatus) {
        setActiveStep(activeStep);
        setCompletedSteps(completedSteps);
      }
    } catch (error) {
      setIsLoading(false);
      handleAuthError(error);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(forms);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setForms(items);
  };

  const repositionForm = (index, newIndex) => {
    if (newIndex && !isNaN(newIndex) && newIndex > 0 && newIndex <= forms.length) {
      const newPosition = parseInt(newIndex) - 1;
      const items = Array.from(forms);
      const [movedItem] = items.splice(index, 1);
      items.splice(newPosition, 0, movedItem);
      setForms(items);
    }
    setEditingIndex(null);
    setNewIndex("");
  };

  const handleIndexChange = (e, index) => {
    e.preventDefault();
    repositionForm(index, newIndex);
  };

  const handleBlur = (index) => {
    repositionForm(index, newIndex);
  };

  const handleContinue = async () => {
    if (forms.length === 0) {
      setForms([initialFormState]);
      return;
    }
    if (!validateForm()) {
      return;
    }

    setIsContinueDisabled(true);

    const data = {
      RegisterSignID: storedRegisterSignID,
      IsSerial: showSignOrder,
      Signatories: forms.map((form, index) => {
        const signatoryData = {
          Name: form.name,
          NotificationType: form.notificationType,
          Email: form.showEmail ? form.email : "",
          CountryCode: form.showMobile ? form.countryCode : "",
          Mobile: form.showMobile ? form.mobile : "",
          Company: form.company,
          SignOrder: index + 1,
          SignatureType: form.signatureType,
          SignatureColor: form.signatureColor,
          SignPage: "FirstPage",
          SignPosition: "TopLeft",
          IsMobile: form.showMobile,
          ExpiryDate: form.expiryDate || "2026-12-16",
          IsExpiring: form.isExpiring || false,
          IsPasswordProtected: form.verificationType === null || form.verificationType === "None" ? false : true, // Updated logic
          LanguageType: form.languageType || "English",
          VerificationType: form.verificationType,
          SignatoriesID: form.signatoriesID,
        };
        return signatoryData;
      }),
    };

    try {
      const response = await axios.post("/v1.0/api/CreateBulkSignatory", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      await workflow("Place Signatories", "3", 3, [1, 2]);
      setIsContinueDisabled(false); // Re-enable the button after the process is complete
    } catch (error) {
      setIsContinueDisabled(false); // Re-enable the button in case of error
      toast.error("There was an error making the request! Please try later");
    }
  };

  const handleBack = async () => {
    workflow("Upload Docs", "1", 1, [0]);
    setActiveStep(1);
    setCompletedSteps([0]);
  };

  return (
    <div className='signatories-forms'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='d-flex align-items-center justify-content-start mb-3 gap-2'>
        <input type='checkbox' className='form-check-input' checked={showSignOrder} onChange={(e) => setShowSignOrder(e.target.checked)} />
        <span> {t("uploadDocFlow.setSignOrder")}</span>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>{t("uploadDocFlow.OrderToltip")}</Tooltip>}>
          <span>
            <AiOutlineInfoCircle size={22} style={{ color: "#a69d9d" }} />
          </span>
        </OverlayTrigger>
      </div>

      <div className='signatory-form-container'>
        <Form>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='forms'>
              {(provided) => (
                <Form {...provided.droppableProps} ref={provided.innerRef}>
                  {forms.map((form, index) => (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <Row className='d-flex justify-content-start align-items-start'>
                            {showSignOrder && (
                              <Col md={1} className='pe-0'>
                                <div className='signOrder' style={{ border: `1px solid ${form.signatureColor}` }} onClick={() => setEditingIndex(index)}>
                                  {editingIndex === index ? (
                                    <Form.Control
                                      type='text'
                                      value={newIndex}
                                      onChange={(e) => setNewIndex(e.target.value)}
                                      onBlur={() => handleBlur(index)}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          handleIndexChange(e, index);
                                        }
                                      }}
                                      autoFocus
                                      style={{ border: "none" }}
                                    />
                                  ) : (
                                    index + 1
                                  )}
                                </div>
                              </Col>
                            )}
                            <Col md={11}>
                              <div className='signatory-form' style={{ border: `1px solid ${form.signatureColor}` }}>
                                <Row>
                                  <Col md={4} className='signatory-form-col'>
                                    {showSignOrder && <img src={dragIcon} alt='drag' style={{ cursor: "grab" }} {...provided.dragHandleProps} />}
                                    <span style={{ fontWeight: "500" }}>
                                      {t("uploadDocFlow.signatory")} {index + 1}
                                    </span>
                                  </Col>
                                  <Col md={3} className='signatory-form-col'></Col>
                                  <Col md={5} className='signatory-form-col action-buttons d-flex justify-content-end align-items-center flex-wrap gap-1'>
                                    <div>
                                      <Form.Group controlId={`formSignatureMethod${index}`}>
                                        <Dropdown onSelect={(e) => handleInputChange(index, "signatureType", e)}>
                                          <Dropdown.Toggle variant='' id={`signatureType${index}`} className='d-flex align-items-center gap-2'>
                                            {form.signatureType === "Needs To Sign" && <BsVectorPen />}
                                            {form.signatureType === "View Only" && <FaEye />}
                                            {form.signatureType}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu style={{ left: "auto", right: 0, minWidth: "unset", padding: "0px" }}>
                                            {signatureTypes.map((signature, sigIndex) => (
                                              <Dropdown.Item key={sigIndex} eventKey={signature} className='d-flex align-items-center gap-2'>
                                                {signature === "Needs To Sign" && <BsVectorPen />}
                                                {signature === "View Only" && <FaEye />}
                                                {signature}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Form.Group>
                                    </div>
                                    <div>
                                      <Form.Group controlId='formNotificationMethod'>
                                        <Dropdown onSelect={(e) => handleInputChange(index, "languageType", e)}>
                                          <Dropdown.Toggle variant='' id='LanguageType' className='d-flex align-items-center gap-2'>
                                            <TfiWorld />
                                            {form.languageType || "English"}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu style={{ left: "auto", right: 0, minWidth: "unset", padding: "0px" }}>
                                            {languageTypes.map((language, index) => (
                                              <Dropdown.Item key={index} eventKey={language}>
                                                {language}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Form.Group>
                                    </div>
                                    <Button variant='none' className='p-0' onClick={() => handleShowModal(index, form.signatoriesID)}>
                                      <img src={deleteSign} alt='icon-loading' />
                                    </Button>

                                    <Button variant='none' className='p-0' onClick={() => handleToggleDropdown(index)}>
                                      <img src={moreIcon} alt='more' />
                                      {showDropdowns[index] && (
                                        <Dropdown show={showDropdowns[index]} onToggle={() => handleToggleDropdown(index)}>
                                          <Dropdown.Menu style={{ left: "auto", right: 0, minWidth: "unset", padding: "0px" }}>
                                            <Dropdown.Item onClick={() => toggleVerificationMethod(index)} className='d-flex align-items-center gap-2'>
                                              <IoKeyOutline />
                                              {t("uploadDocFlow.VerificationMethod")}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </Button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={5}>
                                    <Form.Group controlId={`formSignatoryName${index}`}>
                                      <Form.Label>
                                        {t("uploadDocFlow.name")} <span className='required'>*</span>
                                      </Form.Label>
                                      <Form.Control
                                        list='teamNames'
                                        type='text'
                                        value={form.name}
                                        onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                        placeholder={t("uploadDocFlow.enterName")}
                                      />

                                      {errors[index]?.name && <div className='error-message'>{errors[index].name}</div>}
                                    </Form.Group>
                                  </Col>
                                  <Col md={2}>
                                    <Form.Group controlId={`formNotificationMethod${index}`}>
                                      <Form.Label>{t("uploadDocFlow.notificationType")}</Form.Label>
                                      <Dropdown onSelect={(e) => handleInputChange(index, "notificationType", e)}>
                                        <Dropdown.Toggle variant='' id={`notificationType${index}`}>
                                          {form.notificationType}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {notificationTypes.map((notification, notIndex) => (
                                            <Dropdown.Item key={notIndex} eventKey={notification}>
                                              {notification}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Form.Group>
                                  </Col>
                                  {form.showEmail && (
                                    <Col md={5}>
                                      <Form.Group controlId={`formEmailAddress${index}`}>
                                        <Form.Label>
                                          {t("uploadDocFlow.emailAddress")} <span className='required'>*</span>
                                        </Form.Label>
                                        <Form.Control
                                          list='teamEmails'
                                          type='email'
                                          value={form.email || ""}
                                          onChange={(e) => handleInputChange(index, "email", e.target.value)}
                                          placeholder={t("uploadDocFlow.enterEmail")}
                                        />
                                        {errors[index]?.email && <div className='error-message'>{errors[index].email}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {form.showMobile && (
                                    <Col md={5}>
                                      <Form.Group controlId={`formPhone${index}`}>
                                        <Form.Label>
                                          {t("logScreens.mobileNumber")}
                                          <span className='required'>*</span>
                                        </Form.Label>
                                        <div className='input-group'>
                                          <Form.Select value={form.countryCode || ""} onChange={(e) => handleInputChange(index, "countryCode", e.target.value)} style={{ width: "17%" }}>
                                            <option value='' disabled>
                                              {t("logScreens.Codes")}
                                            </option>
                                            {countryCodes.map((code) => (
                                              <option key={code.lookupID} value={code.lookupID}>
                                                {code.lookupID}
                                              </option>
                                            ))}
                                          </Form.Select>

                                          <Form.Control
                                            type='text'
                                            value={form.mobile || ""}
                                            onChange={(e) => handleInputChange(index, "mobile", e.target.value)}
                                            placeholder={t("logScreens.mobileNumber")}
                                            style={{ width: "calc(85% - 40px)" }}
                                          />
                                        </div>
                                        {errors[index]?.countryCode && <div className='error-message'>{errors[index].countryCode}</div>}
                                        {errors[index]?.mobile && <div className='error-message'>{errors[index].mobile}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {showVerificationMethods[index] && (
                                    <Accordion defaultActiveKey='0' className='custom-dropdown'>
                                      <Accordion.Item eventKey='0'>
                                        <hr className='mb-1' />
                                        <Accordion.Header className='custom-dropdown-header '>
                                          <IoKeyOutline size={20} />
                                          <Form.Label className='mb-0 mx-2'> {t("uploadDocFlow.VerificationMethod")}</Form.Label>
                                        </Accordion.Header>
                                        <hr className='mt-0 mb-2' />
                                        <Accordion.Body className='custom-dropdown-body'>
                                          <Col md={2}>
                                            <div className='verification-method'>
                                              <Form.Group controlId={`formVerificationMethod${index}`}>
                                                <Dropdown onSelect={(e) => handleInputChange(index, "verificationType", e)} style={{ zIndex: "5" }}>
                                                  <Dropdown.Toggle variant='' id={`verificationType${index}`}>
                                                    {form.verificationType || "None"}
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    {verificationMethods.map((method, methodIndex) => (
                                                      <Dropdown.Item key={methodIndex} eventKey={method}>
                                                        {method}
                                                      </Dropdown.Item>
                                                    ))}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </Form.Group>
                                            </div>
                                          </Col>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  )}
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Form>
              )}
            </Droppable>
          </DragDropContext>
        </Form>
      </div>

      <Row>
        {showSignOrder && (
          <Col md={1} className='pe-0 ' style={{ visibility: "hidden" }}>
            <div className='signOrder'>{1}</div>
          </Col>
        )}

        <Col md={11}>
          <div className='add-signatory-btns d-flex justify-content-start mb-5 pb-5 gap-3'>
            <Button variant='' className='ImageCancelBtn p-2' onClick={handleAddRecipient}>
              <IoIosAddCircleOutline size={22} />
              <span> {t("uploadDocFlow.addRecipient")}</span>
            </Button>

            <Button variant='' className='ImageCancelBtn p-2' onClick={handleAddYourself}>
              <IoIosAddCircleOutline size={22} />
              <span> {t("uploadDocFlow.addYourself")}</span>
            </Button>
          </div>
        </Col>
      </Row>

      <div className='fixed-button-container'>
        <Button className='backBtn' id='hide_part_one' onClick={handleBack}>
          {t("uploadDocFlow.back")}
        </Button>

        <Button className='Continue-btn' id='hide_part_one' onClick={handleContinue} disabled={isContinueDisabled}>
          {t("uploadDocFlow.Continue")}
        </Button>
      </div>

      <Modal show={showModal} backdrop='static' keyboard={false} dialogClassName='custom-modal-width' onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{t("uploadDocFlow.ConfirmDelete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteSignatory")}</Modal.Body>
        <Modal.Footer>
          <Button className='modalCancelButton' onClick={() => setShowModal(false)} style={{ color: "black" }}>
            {t("uploadDocFlow.cancel")}
          </Button>
          <Button className='applyBtn' onClick={handleDeleteSignatory}>
            {t("createTeam.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <datalist id='teamEmails'>
        {teamMemberEmails.map((email, idx) => (
          <option key={idx} value={email} />
        ))}
      </datalist>
      <datalist id='teamNames'>
        {teamMemberNames.map((name, idx) => (
          <option key={idx} value={name} />
        ))}
      </datalist>
    </div>
  );
};

export default Signatories;
