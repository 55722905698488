import React, { useEffect, useState } from "react";
import axios from "../../../Api/api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Dropdown, Button, Modal, Form } from "react-bootstrap";
import ShareModal from "../../CommonModal/ShareModal";
import ViewModal from "../../ViewModal/ViewModal";
import SignOrder from "../../SignOrder";
import DocumentSearchFilters from "../../SearchFilter/DocSearchFilter";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import AuditTrailModal from "../../../Pages/AuditTrail/AuditTrailModal";
import DownloadModal from "../../DownloadModal";
import "./style.css";
import noDocImg from "../../../assets/images/NoDocuments (1).png";
import downlaodIcon from "../../../assets/images/document-download.png";
import shareIcon from "../../../assets/images/export.png";
import showIcon from "../../../assets/images/Show.png";
import auditIcon from "../../../assets/images/searchicon.png";
import DashboardComments from "../../DashboardComments/DashboardComments";
import CustomToggle from "../../CustomDots/CustomToggle";
import resendIcon from "../../../assets/images/resend.svg";
import recall from "../../../assets/images/recall.svg";
import { useUser } from "../../../Context/UserContext";
import { MdOutlineMoveDown } from "react-icons/md";
import Loader from "../../Loader/Loader";
import { TbExchange } from "react-icons/tb";
import nottartedIcon from "../../../assets/images/notstarted.svg";
import ChangeOrderModal from "./ChangeSignOrder/ChangeSignOrderModal";

const PendingDocs = () => {
  const { t } = useTranslation();
  const { user, userAccess, accountId } = useUser("");
  const [data, setData] = useState([]);
  const [otherData, setotherData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [signedDocKey, setSignedDocKey] = useState();
  const [dataRow, setDataRow] = useState();
  const [registerSignID, setRegisterSignID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const { reset } = useForm();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [masterDocID, setMasterDocID] = useState("");
  const [showRecallConfirmationModal, setShowRecallConfirmationModal] = useState(false);
  const [selectedRowForRecall, setSelectedRowForRecall] = useState(null);
  const [showResendConfirmationModal, setShowResendConfirmationModal] = useState(false);
  const [selectedRowForResend, setSelectedRowForResend] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [docName, setDocName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [pendingWithOtherRes, setPendingWithOtherRes] = useState([]);
  const [showATModal, setShowATModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [auditTrailURL, setAuditTrailURL] = useState("");
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [newOwner, setNewOwner] = useState("");
  const [emailOptions, setEmailOptions] = useState([]);
  const [showChangeOrderModal, setShowChangeOrderModal] = useState(false);
  const [showNoSignatoryModal, setShowNoSignatoryModal] = useState(false);

  const [viewMode, setViewMode] = useState("pendingWithMe");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePendingwithme = () => {
    setViewMode("pendingWithMe");
  };

  const handlePendingWithOthers = () => {
    setViewMode("pendingWithOthers");
  };

  const [shareFormData, setShareFormData] = useState({
    Email: "",
    Subject: "",
    Body: "",
    MasterDocID: "",
    RegisterSignID: "",
  });

  const token = sessionStorage.getItem("token");

  const fetchEmails = async () => {
    try {
      const response = await axios.get(`/v1.0/api/GetTeam?AccountID=${accountId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEmailOptions(response.data.response);
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, [accountId, token]);

  // Function to handle opening the share modal
  const handleShareModalShow = async (row) => {
    const { masterDocID, registerSignID } = row;
    setMasterDocID(masterDocID);
    setRegisterSignID(registerSignID);
    try {
      const shareData = { ...shareFormData, MasterDocID: masterDocID, RegisterSignID: registerSignID };
      setShareFormData(shareData);
      setShowShareModal(true);
    } catch (error) {
      console.log(t("dashboardCardStatuses.navbar.shareFiletoastErr"), error);
      toast.error(t("dashboardCardStatuses.shareFiletoastErr"));
    }
  };

  const handelView = async (row) => {
    setIsLoading(true);
    try {
      const { signedDocKey } = row;
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: signedDocKey,
        },
      });
      const pdfUrl = response.data.response.document;
      setPdfUrl(pdfUrl);
      setShowModal(true);
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardCardStatuses.fileNotDisplay"));
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle submitting share data
  const handleShareDocument = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/v1.0/api/CreateDocShared", shareFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      if (response.data.statusCode === "1") {
        toast.success(t("dashboardCardStatuses.shareFileSucessToast"));
      } else if (response.data.statusCode === "3") {
        toast.info(t("dashboardCardStatuses.shareFileInfo"));
      }
      setShowShareModal(false);
      setIsLoading(false);
      reset();
    } catch (error) {
      console.log("=======>", error);
      if (error.response && error.response.status === "4") {
        toast.error(t("dashboardCardStatuses.unathorizedErrSharedFile"));
      } else {
        toast.error(t("dashboardCardStatuses.shareFileToastErr"));
      }
      setIsLoading(false);
    }
  };

  //pending with me api
  const fetchPendingData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetPendingSignatoriesList", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.successStatus) {
        setData(response.data.response || []);
        setSearchResults(response.data.response || []);
        if (response.data.response && response.data.response.length > 0) {
          const firstItem = response.data.response[0];
          setSignedDocKey(firstItem.signedDocKey);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error(t("dashboardCardStatuses.errorfetchingUserData"));
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      //pendingwithOthers
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetPendingMasterDocList", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.successStatus) {
        setotherData(response.data.response);
        setPendingWithOtherRes(response.data.response || []);

        if (response.data.response && response.data.response.length > 0) {
          const firstItem = response.data.response[0];
          setSignedDocKey(firstItem.signedDocKey);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingData();
    fetchData();
  }, [token]);

  //Search Filter for pendind with me / pendind with others
  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let response;
      if (startDate || endDate || referenceNo || docNumber || docName) {
        if (viewMode === "pendingWithMe") {
          response = await axios.get("/v1.0/api/GetPendingSignatoriesList", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              StartDate: startDate,
              EndDate: endDate,
              ReferenceNo: referenceNo,
              DocNumber: docNumber,
              DocName: docName,
            },
          });
        } else if (viewMode === "pendingWithOthers") {
          response = await axios.get("/v1.0/api/GetPendingMasterDocList", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              StartDate: startDate,
              EndDate: endDate,
              ReferenceNo: referenceNo,
              DocNumber: docNumber,
              DocName: docName,
            },
          });
        }
      }
      if (viewMode === "pendingWithMe") {
        setSearchResults(response.data.response || []);
      } else if (viewMode === "pendingWithOthers") {
        setPendingWithOtherRes(response.data.response || []);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(t("dashboardCardStatuses.errorfetchingUserData"));
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setStartDate("");
    setEndDate("");
    setReferenceNo("");
    setDocNumber("");
    setDocName("");
    handleSearch();
    fetchPendingData();
    fetchData();
  };

  //handle open link
  const openSigningLink = (signingLink) => {
    window.open(signingLink, "_blank");
  };

  //handle downlaod
  const handleFileDownload = async (row) => {
    setDataRow(row);
    setShowDownloadModal(true);
  };

  const handleView = async (row) => {
    setIsLoading(true);
    try {
      const { signedDocKey } = row;
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: signedDocKey,
        },
      });
      const pdfUrl = response.data.response.document;
      setPdfUrl(pdfUrl);
      setShowModal(true);
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardCardStatuses.fileNotDisplay"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewAuditTrail = (signedDocKey) => {
    const newPdfUrl = `/AuditTrail/id/${signedDocKey}`;
    setAuditTrailURL(newPdfUrl);
    setShowATModal(true);
  };

  const pendingWithMeColumns = [
    { label: t("tablesLabel.date"), key: "pendingWithDate" },
    { label: t("tablesLabel.docName"), key: "docName" },
    { label: t("tablesLabel.docNumber"), key: "docCompanyPrefixNo" },
    { label: t("tablesLabel.refNumber"), key: "referenceNumber" },
    { label: t("tablesLabel.signingLink"), key: "signingLink" },
    { label: t("tablesLabel.createdby"), key: "createdByName" },
    { label: "", key: "actions" },
  ];
  const pendingWithOtherColumns = [
    { label: t("tablesLabel.date"), key: "createdOn" },
    { label: t("tablesLabel.docName"), key: "docName" },
    { label: t("tablesLabel.pendingWith"), key: "pendingWithName" },
    { label: t("tablesLabel.docNumber"), key: "docCompanyPrefixNo" },
    { label: t("tablesLabel.refNumber"), key: "referenceNumber" },
    { label: t("tablesLabel.createdby"), key: "createdByName" },
    { label: "", key: "actions" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${day}/${month}/${year} ${hours}:${formattedMinutes} ${ampm}`;
  };

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  const handleRecallConfirmationModal = (row) => {
    setSelectedRowForRecall(row);
    setShowRecallConfirmationModal(true);
  };

  const handleResendConfirmationModal = (row) => {
    setSelectedRowForResend(row);
    setShowResendConfirmationModal(true);
  };

  //Resend Email
  const handleResendDocument = async () => {
    setIsLoading(true);
    try {
      const { signedDocKey } = selectedRowForResend;
      const response = await axios.get("/v1.0/api/ReminderEmail", {
        headers: {
          Authorization: `Bearer ${token}`,
          SignAuth: signedDocKey,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success("Reminder email successfully sent");
      } else {
        toast.error("Email didn't send. Try Again!");
      }
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardCardStatuses.fileNotDisplay"));
    } finally {
      setIsLoading(false);
      setShowResendConfirmationModal(false);
      setSelectedRowForResend(null);
    }
  };
  //Rcall Document
  const handleRecallDocument = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/RecallSignature",
        {
          RegisterSignID: selectedRowForRecall.registerSignID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success("Signature recalled successfully");
      } else {
        toast.error("Object reference not set to an instance of an object.");
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Internal Error. Try After Some time");
      console.error("Error", error);
      setIsLoading(false);
    } finally {
      setShowRecallConfirmationModal(false);
      setSelectedRowForRecall(null);
    }
  };

  //Transfer Document
  const handleTransferOwnerShip = (row) => {
    setMasterDocID(row.masterDocID);
    setDocName(row.docName);
    setShowTransferModal(true);
  };

  const handleTransfer = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "/v1.0/api/CreateDocumentOwnership",
        {
          MasterDocID: masterDocID,
          NewOwner: newOwner,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("dashboardCardStatuses.OwnershipSuccess"));
      } else {
        toast.error(t("dashboardCardStatuses.FailedTransfer"));
      }
      setIsLoading(false);
      setShowTransferModal(false);
    } catch (error) {
      toast.error(t("dashboardCardStatuses.FailedTransfer"));
      console.error("Error", error);
      setIsLoading(false);
    }
  };

  const handleOpenChangeOrderModal = async (row) => {
    try {
      setIsLoading(true); 

      const response = await axios.get(`/v1.0/api/GetNotStartedSignatories?RegisterSignID=${row.registerSignID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.successStatus && response.data.response.signatories.length > 0) {
        setRegisterSignID(row.registerSignID);
        setShowChangeOrderModal(true);
      } else {
        setShowNoSignatoryModal(true);
      }
    } catch (error) {
      console.error("Error fetching signatories:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <Grid className='container' style={{ marginTop: "12px" }}>
      <Grid>
        {/* Loader overlay */}
        {isLoading && (
          <div className='loader-overlay'>
            <Loader />
          </div>
        )}

        <div className='py-3 p-3 container pendingtable'>
          <h4>{t("dashboardCardStatuses.pendingDocs")}</h4>

          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <div className='d-flex gap-3 '>
                <Button className={`SignButton ${viewMode === "pendingWithMe" ? "active" : ""}`} onClick={handlePendingwithme}>
                  {t("menuButtons.pendingWithMe")}
                </Button>
                <Button className={`SignButton ${viewMode === "pendingWithOthers" ? "active" : ""}`} onClick={handlePendingWithOthers}>
                  {t("menuButtons.pendingWithOthers")}
                </Button>
              </div>
            </div>
            <div className='search-filters-container'>
              <DocumentSearchFilters
                startDate={startDate}
                endDate={endDate}
                referenceNo={referenceNo}
                docNumber={docNumber}
                docName={docName}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setReferenceNo={setReferenceNo}
                setDocNumber={setDocNumber}
                setDocName={setDocName}
                handleSearch={handleSearch}
                clearSearch={clearSearch}
              />
            </div>
          </div>
          {/* Table */}
          {viewMode === "pendingWithMe" && (
            <>
              <TableContainer component={Paper} style={{ height: "66vh" }}>
                <Table stickyHeader style={{ border: "none" }}>
                  {/* Table Headers */}
                  <TableHead className='customTableHead'>
                    <TableRow>
                      {pendingWithMeColumns.map((pendingWithMeColumns) => (
                        <TableCell key={pendingWithMeColumns.label} style={{ backgroundColor: "#FDF7E8" }} className='TableHeaderCell'>
                          {pendingWithMeColumns.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* Table Body */}
                  <TableBody>
                    {searchResults.length === 0 && (
                      <TableRow>
                        <td colSpan='6' className='p-5 text-center'>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <img alt='iconLoading' src={noDocImg} style={{ height: "150px", width: "200px" }} />
                            {t("dashboardCardStatuses.notDocsFound")}
                          </div>
                        </td>
                      </TableRow>
                    )}

                    {searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <TableRow key={index}>
                        {/* Table Cells */}

                        <TableCell className='TableCell'>{row.pendingWithDate ? formatDate(row.pendingWithDate) : ""}</TableCell>
                        <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                          {row.docName}
                        </TableCell>
                        <TableCell className='TableCell'>{row.docCompanyPrefixNo}</TableCell>
                        <TableCell className='TableCell'>{row.referenceNumber}</TableCell>
                        <TableCell className='TableCell'>
                          <Button className='SignNowbtn ' variant='text' onClick={() => openSigningLink(row.signingLink)}>
                            {t("dashboardCardStatuses.OpenToSign")}
                          </Button>
                        </TableCell>
                        <TableCell onClick={(e) => handleCellClick(e)} className='TableCell truncate-cell'>
                          {row.createdByName}
                        </TableCell>

                        {userAccess.remainingDays <= 0 ? (
                          <>
                            <TableCell className='py-0'>
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                              </Dropdown>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell className='py-0'>
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />

                                <Dropdown.Menu>
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileDownload(row)}>
                                    <img src={downlaodIcon} alt='iconLoading' />
                                    {t("dashboardCardStatuses.download")}
                                  </Dropdown.Item>
                                  {user.AccountType === "B" && user.RoleID === 3 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {userAccess.docShare === true ? (
                                        <>
                                          <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShareModalShow(row)}>
                                            <img src={shareIcon} alt='iconLoading' />
                                            {t("dashboardCardStatuses.share")}
                                          </Dropdown.Item>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleView(row)}>
                                    <img src={showIcon} alt='iconLoading' />
                                    {t("dashboardCardStatuses.view")}
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <SignOrder masterDocID={row.masterDocID} />
                                  </Dropdown.Item>

                                  {userAccess.auditTrail === true ? (
                                    <>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewAuditTrail(row.signedDocKey)}>
                                        <img src={auditIcon} alt='iconLoading' />
                                        {t("dashboardCardStatuses.viewAuditTrail")}
                                      </Dropdown.Item>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <Dropdown.Item>
                                    <DashboardComments registerSignID={row.registerSignID} />
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                <div className='showHideNoDocs'>
                  {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, data.length)}
                  {t("tablesLabel.documentsof")} {data.length} {t("tablesLabel.document")}
                </div>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(data.length / rowsPerPage)}
                    variant='outlined'
                    shape='rounded'
                    page={page + 1}
                    onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                    sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
                  />
                </Stack>
              </div>
            </>
          )}

          {viewMode === "pendingWithOthers" && (
            <>
              <TableContainer component={Paper} style={{ height: "66vh" }}>
                <Table stickyHeader style={{ border: "none" }}>
                  <TableHead className='customTableHead'>
                    <TableRow style={{ padding: 0 }}>
                      {pendingWithOtherColumns.map((pendingWithOtherColumns) => (
                        <TableCell key={pendingWithOtherColumns.label} style={{ backgroundColor: "#FDF7E8" }} className='TableHeaderCell'>
                          {pendingWithOtherColumns.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {pendingWithOtherRes.length === 0 && (
                      <TableRow>
                        <td colSpan='6' className='p-5 text-center'>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <img alt='iconLoading' src={noDocImg} style={{ height: "150px", width: "200px" }} />
                            {t("dashboardCardStatuses.notDocsFound")}
                          </div>
                        </td>
                      </TableRow>
                    )}

                    {pendingWithOtherRes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className='TableCell'>{row.createdOn ? formatDate(row.createdOn) : ""}</TableCell>
                        <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                          {row.docName}
                        </TableCell>

                        <TableCell className='TableCell' classname>
                          {row.pendingWithName}
                        </TableCell>
                        <TableCell className='TableCell'>{row.docCompanyPrefixNo}</TableCell>
                        <TableCell className='TableCell'>{row.referenceNumber}</TableCell>
                        <TableCell onClick={(e) => handleCellClick(e)} className='TableCell truncate-cell'>
                          {row.createdByName}
                        </TableCell>

                        {userAccess.remainingDays <= 0 ? (
                          <>
                            <TableCell className='TableCell py-0'>
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                              </Dropdown>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell className='TableCell py-0'>
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />

                                <Dropdown.Menu>
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileDownload(row)}>
                                    <img src={downlaodIcon} alt='iconLoading' />
                                    {t("dashboardCardStatuses.download")}
                                  </Dropdown.Item>

                                  {user.AccountType === "B" && user.RoleID === 3 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {userAccess.docShare === true ? (
                                        <>
                                          <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShareModalShow(row)}>
                                            <img src={shareIcon} alt='iconLoading' />
                                            {t("dashboardCardStatuses.share")}
                                          </Dropdown.Item>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}

                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handelView(row)}>
                                    <img src={showIcon} alt='iconLoading' />
                                    {t("dashboardCardStatuses.view")}
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <SignOrder masterDocID={row.masterDocID} />
                                  </Dropdown.Item>

                                  {userAccess.auditTrail === true ? (
                                    <>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewAuditTrail(row.signedDocKey)}>
                                        <img src={auditIcon} alt='iconLoading' />
                                        {t("dashboardCardStatuses.viewAuditTrail")}
                                      </Dropdown.Item>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {userAccess.resendNotification === true ? (
                                    <>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleResendConfirmationModal(row)}>
                                        <img src={resendIcon} alt='iconLoading' />
                                        {t("dashboardCardStatuses.resendNotification")}
                                      </Dropdown.Item>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {userAccess.recalled === true ? (
                                    <>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleRecallConfirmationModal(row)}>
                                        <img src={recall} alt='iconLoading' />
                                        {t("dashboardCardStatuses.Recall")}
                                      </Dropdown.Item>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <Dropdown.Item>
                                    <DashboardComments registerSignID={row.registerSignID} />
                                  </Dropdown.Item>
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleTransferOwnerShip(row)}>
                                    <MdOutlineMoveDown style={{ color: "gray" }} size={22} />
                                    {t("dashboardCardStatuses.TransferOwnerShip")}
                                  </Dropdown.Item>
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleOpenChangeOrderModal(row)}>
                                    <TbExchange style={{ color: "gray" }} size={22} />
                                    {t("dashboardCardStatuses.ChangeSignOrder")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                <div className='showHideNoDocs'>
                  {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, pendingWithOtherRes.length)} {t("tablesLabel.documentsof")} {data.length} {t("tablesLabel.document")}
                </div>{" "}
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(pendingWithOtherRes.length / rowsPerPage)}
                    variant='outlined'
                    shape='rounded'
                    page={page + 1}
                    onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                    sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
                  />
                </Stack>
              </div>
            </>
          )}
        </div>
      </Grid>

      {/* Share and View modals */}
      <ShareModal
        show={showShareModal}
        handleClose={() => setShowShareModal(false)}
        formData={shareFormData}
        handleShare={handleShareDocument}
        handleChange={(e) => setShareFormData({ ...shareFormData, [e.target.name]: e.target.value })}
        masterDocID={masterDocID}
        registerSignID={registerSignID}
      />

      <ViewModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setPdfUrl("");
        }}
        pdfUrl={pdfUrl}
      />

      <DownloadModal
        show={showDownloadModal}
        dataRow={dataRow}
        handleClose={() => {
          setShowDownloadModal(false);
        }}
      />

      <AuditTrailModal
        show={showATModal}
        auditTrailURL={auditTrailURL}
        handleClose={() => {
          setShowATModal(false);
          setAuditTrailURL("");
        }}
      />

      <Modal show={showRecallConfirmationModal} backdrop='static' keyboard={false} onHide={() => setShowRecallConfirmationModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title> {t("dashboardCardStatuses.confirmRecall")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>{t("dashboardCardStatuses.SureRecall")}</Modal.Body>
        <Modal.Footer>
          <Button variant='none' className='modalCancelButton' onClick={() => setShowRecallConfirmationModal(false)}>
            {t("dashboardCardStatuses.cancel")}
          </Button>
          <Button className='applyBtn' variant='none' onClick={handleRecallDocument}>
            {t("dashboardCardStatuses.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showResendConfirmationModal} backdrop='static' keyboard={false} onHide={() => setShowResendConfirmationModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title> {t("dashboardCardStatuses.confirmResend")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>{t("dashboardCardStatuses.sureResend")}</Modal.Body>
        <Modal.Footer>
          <Button variant='none' className='modalCancelButton' onClick={() => setShowResendConfirmationModal(false)}>
            {t("dashboardCardStatuses.cancel")}
          </Button>
          <Button className='applyBtn' variant='none' onClick={handleResendDocument}>
            {t("dashboardCardStatuses.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTransferModal} backdrop='static' keyboard={false} onHide={() => setShowTransferModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title> {t("dashboardCardStatuses.TransferOwnerShip")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <Form>
            <Form.Group controlId='formDocName'>
              <Form.Label>{t("dashboardCardStatuses.document")}</Form.Label>
              <Form.Control type='text' readOnly value={docName} disabled />
            </Form.Group>
            <Form.Group controlId='formNewOwner' className='mt-3'>
              <Form.Label>{t("dashboardCardStatuses.TransferTo")}</Form.Label>
              <Form.Control list='email-options' type='text' placeholder='e.g. mail@mail.com' value={newOwner} onChange={(e) => setNewOwner(e.target.value)} />
              <datalist id='email-options'>
                {emailOptions.map((option, index) => (
                  <option key={index} value={option.emailID}>
                    {option.name}
                  </option>
                ))}
              </datalist>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='none' className='modalCancelButton' onClick={() => setShowTransferModal(false)}>
            {t("dashboardCardStatuses.cancel")}
          </Button>
          <Button className='applyBtn' variant='none' onClick={handleTransfer}>
            {t("dashboardCardStatuses.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
      <ChangeOrderModal show={showChangeOrderModal} handleClose={() => setShowChangeOrderModal(false)} registerSignID={registerSignID} />
      <Modal show={showNoSignatoryModal} centered onHide={() => setShowNoSignatoryModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", height: "250px" }}>
          <p>{t("dashboardCardStatuses.notSignatoryLeft")}</p>
          <img src={nottartedIcon} alt='NotStarted ' style={{ height: "100px", widows: "100px" }} />
        </Modal.Body>
      </Modal>
    </Grid>
  );
};

export default PendingDocs;
