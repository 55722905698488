import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import DOMPurify from "dompurify";
import "../../Components/DashboardStatuses/Draftstatus/style.css";
import "./style.css";

function AIGeneratedPDF() {
  const [aiGeneratedTextResp, setAiGeneratedTextResp] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState();

  // Extract ID from the URL
  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");
  const idIndex = parts.indexOf("id");

  useEffect(() => {
    const GetAITextForPage = async () => {
      if (idIndex !== -1 && idIndex < parts.length - 1) {
        const pathAfterId = parts.slice(idIndex + 1).join("/");
        setAuth(pathAfterId);

        try {
          // Fetch AI-generated text from the API
          const response = await axios.get("v1.0/api/GetAITextForPage", {
            headers: {
              SignAuth: pathAfterId,
            },
          });

          const content = response.data.response.aiGeneratedText;
          const isTableContent = content.includes("<table");

          // Format text based on content type
          let formattedText;
          if (isTableContent) {
            formattedText = content.replace(/<br\s*\/?>/gi, ""); // Remove line breaks for table content
          } else {
            formattedText = content.replace(/\n/g, "<br>"); // Replace newlines with <br> for plain text
          }

          // Sanitize the formatted text before setting it in the state
          const sanitizedText = DOMPurify.sanitize(formattedText);
          setAiGeneratedTextResp(sanitizedText);
          setIsLoading(false);
        } catch (error) {
          console.error("API Error:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    GetAITextForPage();
  }, [auth, idIndex, parts]);

  return (
    <div className='openAITable'>
      {/* Render sanitized HTML content */}
      {!isLoading && aiGeneratedTextResp && <p dangerouslySetInnerHTML={{ __html: aiGeneratedTextResp }} />}
    </div>
  );
}

export default AIGeneratedPDF;
