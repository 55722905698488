import axios from "axios";

 //const BASE_URL = "https://api-dev.iamsigner.com";
// const BASE_URL = "https://test-iamsigner.acquireitsol.com";
// const BASE_URL = "https://api-demo.iamsigner.com";
// const BASE_URL = "https://daarconn-uat.darglobal.co.uk";
const BASE_URL = "https://api-demo1.iamsigner.com";
// const BASE_URL = "https://ap-mac.iamsigner.com";

export default axios.create({
  baseURL: BASE_URL,
});
