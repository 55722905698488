import React, { useState, useEffect } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { Button, Col, Container, Form, InputGroup, ListGroup, Modal, Row, Dropdown, Accordion, Offcanvas } from "react-bootstrap";
import { useUser } from "../../../Context/UserContext";
import "./Thirdstep.css";
import { toast } from "react-toastify";
import axios from "../../../Api/api";
import PeopleImage from "../../../assets/images/people.png";
import { useNavigate } from "react-router-dom";
import CCEmail from "./CCEmail";
import { useTranslation } from "react-i18next";
import ViewFileIcon from "../../../assets/images/Show.png";
import DeleteIcon from "../../../assets/images/Delete.svg";
import editIcon from "../../../assets/images/EditThird.svg";
import pdfFileIcon from "../../../assets/images/pdfFile.svg";
import commentIcon from "../../../assets/images/sendIcon.svg";
import UploadSupportingDocs from "../../../assets/images/cloud-upload.svg";
import CustomToggle from "../../CustomDots/CustomToggle";
import Loader from "../../Loader/Loader";
import { useDropzone } from "react-dropzone";
import EmailIcon from "../../../assets/images/sms-edit.svg";
import { useForm } from "react-hook-form";
import CustomImage from "../../../assets/images/custom-message.svg";
import AdvancedSettings from "../../../assets/images/Advanced settings.svg";
import FolderSelection from "./FolderSelection";

const Thirdstep = ({ setActiveStep, setCompletedSteps }) => {
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token");
  const storedRegisterSignID = localStorage.getItem("registerSignID");
  const navigate = useNavigate();
  const [selectedFolderID, setSelectedFolderID] = useState(null);
  const [selectedFolderName, setSelectedFolderName] = useState("");
  const [isCanvasVisible, setIsCanvasVisible] = useState(false);

  const handleSelectFolder = (folderID, folderName) => {
    setSelectedFolderID(folderID);
    setSelectedFolderName(folderName);
    // console.log("Folder selected in ThirdStep:", folderID, folderName);
  };

  const { register, watch, reset } = useForm({
    defaultValues: {
      //  im using IsAllowTransfer at the place of download supporting documents when tahir fix the backend change the key... (talha)
      IsAllowTransfer: true,
      IsAllowDeclined: true,
      IsAllowPrint: false,
      IsAllowDownload: true,
      IsAttachCompletedDoc: true,
      CanAddComment: true,
      CanAddSupportingDocument: true,
      CanDownloadAuditTrail: true,
      IsAttachAuditTrialDoc: true,
      SetReminderBefore1Day: false,
      AutoReminderInDays: "",
      SetExpiryDate: "",
    },
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedText, setEditedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [docCommentsIDs, setDocCommentsIDs] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [commentToDeleteIndex, setCommentToDeleteIndex] = useState(null);
  const [deletingCommentId, setDeletingCommentId] = useState(null);
  const [supportingDocIDs, setSupportingDocIDs] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deletingSupportingDocID, setDeletingSupportingDocID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [documentPath, setDocumentPath] = useState(null);
  const [isSupported, setIsSupported] = useState();
  const { user, userAccess } = useUser();

  const handleFileUpload = async (files) => {
    const allowedFormats = [
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/plain",
    ];

    const newFiles = Array.from(files);

    const allowedFiles = newFiles.filter((file) => allowedFormats.includes(file.type));

    if (allowedFiles.length === 0) {
      setErrorMessage(t("uploadDocFlow.fileFormatSupport"));
      setIsLoading(false);
    } else {
      setErrorMessage("");
      const base64Files = [];
      for (const file of allowedFiles) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const base64Data = e.target.result.split(",")[1];
          base64Files.push({
            name: file.name,
            base64: base64Data,
            MasterDocID: null,
          });

          if (base64Files.length === allowedFiles.length) {
            const uploadedFileNames = uploadedFiles.map((fileName) => fileName);

            const duplicateFiles = base64Files.filter((allowedfile) => uploadedFileNames.includes(allowedfile.name));

            if (duplicateFiles.length > 0) {
              setErrorMessage(t("uploadDocFlow.filewithSameNameExits"));
              toast.error(t("uploadDocFlow.filewithSameNameExits"));
              setIsLoading(false);
            } else {
              const fileExtension = file.name.split(".").pop();

              const requestBody = {
                CreatedBy: user.email,
                registerSignID: storedRegisterSignID,
                MasterDocID: null,
                SupportingDocName: file.name,
                SupportingDocExtension: fileExtension,
                SupportingDocFile: base64Data,
              };

              setIsLoading(true);
              try {
                const response = await axios.post("/v1.0/api/UploadSupportngDoc", requestBody, {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                });

                if (response.data.successStatus) {
                  setUploadedFiles([...uploadedFiles, ...base64Files.map((allowFile) => allowFile.name)]);

                  setSupportingDocIDs((prevIDs) => [...prevIDs, response.data.response.supportingDocID]);
                  toast.success(t("uploadDocFlow.DocUploadedsuccess"));
                  setIsLoading(false);
                } else {
                  toast.error(t("uploadDocFlow.failedUpload"));
                  setIsLoading(false);
                }
              } catch (error) {
                if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
                  sessionStorage.removeItem("token");
                  localStorage.clear();
                  setIsLoading(false);
                  navigate("/");
                  toast.info(t("uploadDocFlow.sessionExpire"));
                } else {
                  toast.error(t("uploadDocFlow.failedUpload"));
                }
                setIsLoading(false);
              }
            }
          }
        };

        reader.readAsDataURL(file);

        reader.onerror = (error) => {
          setIsLoading(false);
        };
      }
    }
  };

  useEffect(() => {}, [uploadedFiles, supportingDocIDs, userAccess]);

  useEffect(() => {
    const apiUrl = `/v1.0/api/GetSupportingDocs?RegisterSignID=${storedRegisterSignID}`;
    setIsLoading(true);
    try {
      axios
        .get(apiUrl, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (Array.isArray(response.data.response)) {
              const uploadedDocs = response.data.response.map((doc) => doc.supportingDocName);
              setUploadedFiles(uploadedDocs);

              const supportingID = response.data.response.map((doc) => doc.supportingDocID);
              setSupportingDocIDs(supportingID);
            }
          }
        });
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFileview = async (supportingDocID) => {
    try {
      const response = await axios.get(`/v1.0/api/GetSingleSupportingDoc?SupportingDocID=${supportingDocID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setDocumentPath(response.data.response.supportingDocPath);
      const documentExtension = response.data.response.supportingDocPath.split(".").pop(); // Extract the extension

      if (documentExtension === "docx" || documentExtension === "xlsx" || documentExtension === "ppt") {
        setIsSupported(false);
      } else {
        setIsSupported(true);
      }

      setShowModal(true);
    } catch (error) {
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.filecantView"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileDownload = (file) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = file;
    downloadLink.download = "file";
    downloadLink.click();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDocumentPath("");
  };

  const DeleteFileModal = (supportingDocID, index) => {
    setDeletingSupportingDocID(supportingDocID);
    setDeletingIndex(index);
    setFileModal(true);
  };

  useEffect(() => {}, [deletingIndex, deletingSupportingDocID]);

  const handleFileCloseModal = () => {
    setFileModal(false);
  };

  const handleFileDelete = async (supportingDocID, index) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteSupportingDoc",
        {
          SupportingDocID: supportingDocID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.data.successStatus) {
        // Remove the file at the specified index from the frontend state
        const updatedUploadedFiles = [...uploadedFiles];
        updatedUploadedFiles.splice(index, 1);
        setUploadedFiles(updatedUploadedFiles);

        // Remove the corresponding masterDocID from the frontend state
        const updatedsupportingDocIDs = [...supportingDocIDs];
        updatedsupportingDocIDs.splice(index, 1);
        setSupportingDocIDs(updatedsupportingDocIDs);

        setIsLoading(false);
      }

      toast.success(t("uploadDocFlow.fileDeleted"));
    } catch (error) {
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
      toast.error(t("uploadDocFlow.errdeleteFile"));
      setIsLoading(false);
    } finally {
      handleFileCloseModal();
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // comment section
  const handleCommentChange = (e) => {
    setNewComment(e.target.value);

    if (e.key === "Enter") {
      if (!e.target.value) {
        // setErrorMessage(t("uploadDocFlow.enterComment"));
        toast.error(t("uploadDocFlow.enterComment"));
        return;
      }
      setErrorMessage("");

      addComment(e);
    }
  };

  const addComment = async () => {
    if (!newComment) {
      // setErrorMessage(t("uploadDocFlow.enterComment"));
      toast.error(t("uploadDocFlow.enterComment"));
      return;
    }

    setErrorMessage("");
    const requestBody = {
      Comments: newComment,
      AdhockUserID: "3c9a2b68-f820-4fd2-a32a-5eec2e5f00cd",
      CommentPostedBy: user.fullName,
      CreatedBy: user.email,
      MasterDocID: null,
      RegisterSignID: storedRegisterSignID,
    };

    setIsLoading(true);

    try {
      const response = await axios.post("/v1.0/api/CreateDocComments", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200) {
        const currentDate = new Date();
        setComments([
          ...comments,
          {
            text: newComment,
            time: `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`,
            date: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
            edited: false,
          },
        ]);
        setDocCommentsIDs([...docCommentsIDs, response.data.response]);

        setNewComment("");
        setIsLoading(false);
        toast.success(t("uploadDocFlow.commentAdded"));
      } else {
        setIsLoading(false);
        toast.error(t("uploadDocFlow.failedToAdd"));
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.failedToAdd"));
      }
    }
  };

  const handleDeleteComment = async (docCommentsID, index) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteDocComments",
        { DocCommentsID: docCommentsID },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        const updatedComments = [...comments];
        updatedComments.splice(index, 1);
        const updatedDocCommentsIDs = [...docCommentsIDs];
        updatedDocCommentsIDs.splice(index, 1);
        setComments(updatedComments);
        setDocCommentsIDs(updatedDocCommentsIDs);
        setIsLoading(false);
        toast.success(t("uploadDocFlow.commentDeleted"));
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info("session expired");
      } else {
        toast.error(t("uploadDocFlow.failedTodelete"));
      }
    } finally {
      setShowConfirmModal(false);
    }
  };

  const toggleEdit = (index) => {
    setEditingIndex(index);
    setEditedText(comments[index].text);
  };

  const saveEditedComment = async (index) => {
    const docCommentsID = docCommentsIDs[index];
    const updatedText = editedText;

    const requestBody = {
      DocCommentsID: docCommentsID,
      Comments: updatedText,
    };

    setIsLoading(true);
    try {
      const response = await axios.put("/v1.0/api/UpdateDocComments", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 200) {
        const updatedComments = [...comments];
        updatedComments[index].text = updatedText;
        updatedComments[index].edited = true;
        updatedComments[index].timestamp = new Date().toLocaleString();
        setComments(updatedComments);
        setEditingIndex(-1);
        setIsLoading(false);
        toast.success(t("uploadDocFlow.commentUpdate"));
      } else {
        toast.error(t("uploadDocFlow.failedToUpdate"));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.failedToUpdate"));
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      axios
        .get(`/v1.0/api/GetDocComments?RegisterSignID=${storedRegisterSignID}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.response;
            const allComments = responseData.map((comment) => ({
              text: comment.comments,
              date: comment.createdDate,
              time: comment.createdTime,
              edited: comment.modifiedOn ? true : false,
            }));
            setComments(allComments);
            setDocCommentsIDs(responseData.map((comment) => comment.docCommentsID));
          }
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  // comments delete confirmation modal
  const handleShowConfirmModal = (docCommentsID, index) => {
    setDeletingCommentId(docCommentsID);
    setCommentToDeleteIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  // send and sign button modalss
  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    localStorage.clear();
    navigate("/home");
  };

  const handleYesButton = async () => {
    const formData = watch();

    // Call the API to send the custom message
    await sendCustomMessage(formData);

    closeConfirmationModal();
    setIsLoading(true);

    const requestBody = {
      RegisterSignID: storedRegisterSignID,
      MasterDocID: "e00a548a-d73d-4a00-8263-7e71664bb75c",
      SignatoriesID: "474386bf-dac6-4957-932f-80185c12493e",
      ActivityStatus: "GenerateLink",
      Remarks: "Remarks",
    };

    try {
      const response = await axios.post("/v1.0/api/SendAndSign", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        openSuccessModal();
        setIsLoading(false);
      }

      if (response.data.response.isSuccess) {
        const StepData = {
          SignWorkFlowActivityName: "EmailSent",
          SignWorkFlowActivityRef: "",
          SignWorkFlowActivityClass: "RED",
          RegisterSignID: storedRegisterSignID,
          IsMobile: false,
        };

        try {
          setIsLoading(true);
          const response = await axios.post("/v1.0/api/CreateWorkFlowActivity", StepData, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          setCompletedSteps((prevSteps) => [...prevSteps, 4]);

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
            sessionStorage.removeItem("token");
            localStorage.clear();
            setIsLoading(false);
            navigate("/");
            toast.info(t("uploadDocFlow.sessionExpire"));
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something wrong. Try later");

      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    }
  };

  const handleBack = async () => {
    const StepData = {
      SignWorkFlowActivityName: "Place Signatories",
      SignWorkFlowActivityRef: "2",
      SignWorkFlowActivityClass: "RED",
      RegisterSignID: storedRegisterSignID,
      IsMobile: false,
    };

    try {
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateWorkFlowActivity", StepData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setIsLoading(false);

      if (response.data.successStatus) {
        setActiveStep(3);
        setCompletedSteps([1, 2]);
      } else {
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx",
    onDrop: handleFileUpload,
    multiple: true,
  });

  // Send Custom Message

  const sendCustomMessage = async (data) => {
    setIsLoading(true);
    const registerSignId = localStorage.getItem("registerSignID");

    try {
      const apiBody = {
        RegisterSignID: registerSignId || null,
        CompletedFolderID: selectedFolderID || null,
        IsAllowTransfer: data.IsAllowTransfer || false,
        IsAllowPrint: data.IsAllowPrint || false,
        IsAllowDownload: data.IsAllowDownload || false,
        IsAllowDeclined: data.IsAllowDeclined || false,
        IsAttachAuditTrialDoc: data.IsAttachAuditTrialDoc || false,
        IsAttachCompletedDoc: data.IsAttachCompletedDoc || false,
        CanAddComment: data.CanAddComment || false,
        CanAddSupportingDocument: data.CanAddSupportingDocument || false,
        CanDownloadAuditTrail: data.CanDownloadAuditTrail || false,
        SetReminderBefore1Day: data.SetReminderBefore1Day || false,
        SetExpiryDate: data.SetExpiryDate || null,
        AutoReminderInDays: data.AutoReminderInDays || null,
        CustomSubject: data.CustomSubject || null,
        CustomEmailMessage: data.CustomEmailMessage || null,
        CreatedBy: user.email || "",
        CreatedOn: new Date().toISOString() || "",
      };

      const response = await axios.post("/v1.0/api/UpsertRegisterSignPermission", apiBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode == "1") {
        reset();
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add custom message !");
    } finally {
      setIsLoading(false);
    }
  };

  const currentDateExpiry = new Date().toISOString().split("T")[0];

  return (
    <Container className='upload-container-thirdStep'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='upload_body' id='part_one'>
        <div>
          {userAccess.supportDocs === true ? (
            <>
              <div style={{ marginTop: "1rem" }}>
                <Row>
                  <Col>
                    <div className='uplad_box'>
                      <Form.Group controlId='file-input-add' className='file-upload_temp'>
                        <div {...getRootProps({ className: "dropzoneUploadFile" })}>
                          <input {...getInputProps()} className='temp_file-input-additional d-none' />
                          <img alt='iconLoading' src={UploadSupportingDocs} />
                          <br />
                          <div className='upload_doc_part'>
                            <Form.Label>{t("uploadDocFlow.UploadsupportedDoc")}</Form.Label>
                            <Form.Label style={{ color: "#0C9AD7", margin: "0px 5px", textDecoration: "underline" }}>{t("uploadDocFlow.here")}</Form.Label>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                {errorMessage && (
                  <p className='error-message'>
                    <BiErrorCircle className='mb-1 mx-2' />
                    {errorMessage}
                  </p>
                )}
                <div className='upload_file_box' id='upload_file_bx '>
                  {uploadedFiles.map((fileName, index, file) => (
                    <div key={index} className='mb-2 uploaded-file d-flex align-items-center justify-content-between px-3 border-bottom '>
                      <p className='m-0 d-flex align-items-center gap-2'>
                        <img src={pdfFileIcon} alt='iconLoading' />
                        {fileName}
                      </p>
                      <Dropdown>
                        <Dropdown.Toggle as={CustomToggle}>
                          <Dropdown.Menu>
                            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileview(supportingDocIDs[index])}>
                              <img src={ViewFileIcon} alt='iconLoading' />
                              {t("uploadDocFlow.view")}
                            </Dropdown.Item>
                            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => DeleteFileModal(supportingDocIDs[index], index)}>
                              <img src={DeleteIcon} alt='iconLoading' />
                              {t("uploadDocFlow.delete")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown.Toggle>
                      </Dropdown>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* Message to Recipients */}

          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header className='m-0'>
                <div className='d-flex align-items-center gap-3'>
                  <img src={CustomImage} alt='iconLoading' />
                  <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("uploadDocFlow.MessageRecipients")}</label>
                </div>
              </Accordion.Header>
              <Accordion.Body className='p-3'>
                <Form onSubmit={sendCustomMessage}>
                  <Form.Group className='mb-3' controlId='formSubject'>
                    <Form.Label className='mb-2'>{t("Comments.Subject")}</Form.Label>
                    <Form.Control type='text' placeholder={t("Comments.addSubject")} {...register("CustomSubject")} />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formMessage'>
                    <Form.Label className='mb-2'>{t("Comments.message")}</Form.Label>
                    <Form.Control as='textarea' rows={5} placeholder={t("Comments.CustomMessage")} {...register("CustomEmailMessage")} />
                  </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* Comment Input */}
          <div className='my-3 '>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='2'>
                <Accordion.Header className='m-0'>
                  <div className='d-flex align-items-center gap-3'>
                    <img src={EmailIcon} alt='iconLoading' />
                    <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("uploadDocFlow.sendCopy")}</label>
                  </div>
                </Accordion.Header>

                <Accordion.Body className='p-3'>
                  <div>
                    {/* Email CC */}
                    <CCEmail />
                    {/*Comment  */}
                    <label className='mt-3 mb-2'>{t("uploadDocFlow.comment")}</label>
                    <div className='d-flex align-items-center justify-content-between mb-3 gap-2'>
                      <InputGroup>
                        <Form.Control type='text' placeholder={t("Comments.addCommentHere")} value={newComment} onChange={handleCommentChange} onKeyPress={handleCommentChange} />
                      </InputGroup>
                      <img src={commentIcon} alt='send button' style={{ width: "38px" }} onClick={addComment} className='comment-button ' />
                    </div>
                    <ListGroup>
                      {comments.map((comment, index) => (
                        <ListGroup.Item key={index} className='comment-list-third'>
                          <Row>
                            <Col md={6} className='pe-0 d-flex align-items-center gap-3'>
                              <img src={user.profilePic ? user.profilePic : PeopleImage} roundedCircle className='avatar' alt="User's avatar" />
                              <div className='comment-header mb-0'>
                                {user.fullName}
                                <span className='timestamp'>
                                  {comment.date}
                                  <span className='mx-2'></span>
                                  {comment.time}
                                </span>
                                {comment.edited && <span className='edited-label mx-2 fw-normal'>edited</span>}
                              </div>
                            </Col>
                            <Col md={6} className='d-flex justify-content-end gap-3'>
                              {editingIndex !== index ? (
                                <>
                                  <img src={editIcon} alt='iconLoading' className='ThirdStepEdit' onClick={() => toggleEdit(index)} />
                                  <img src={DeleteIcon} alt='iconLoading' className='ThirdStepEdit' onClick={() => handleShowConfirmModal(docCommentsIDs[index], index)} />
                                </>
                              ) : null}
                            </Col>
                            <Col className='my-3'>
                              {editingIndex === index ? (
                                // Editing mode
                                <div className='d-flex align-items-center gap-3'>
                                  <Form.Control type='text' value={editedText} onChange={(e) => setEditedText(e.target.value)} />
                                  <Button variant='' className='applyBtn' style={{ width: "10%" }} onClick={() => saveEditedComment(index)}>
                                    {t("uploadDocFlow.save")}
                                  </Button>
                                </div>
                              ) : (
                                // Viewing mode
                                <>
                                  <div className='comment-body'>
                                    <div className='comment-text'>{comment.text}</div>
                                  </div>
                                </>
                              )}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          {/*Advanced Settings  */}

          <div className='ThirdpaginationDivs my-3 '>
            <Accordion defaultActiveKey='0' className='comments-container'>
              <Accordion.Item eventKey='2'>
                <Accordion.Header className='m-0'>
                  <div className='d-flex align-items-center gap-3'>
                    <img src={AdvancedSettings} alt='iconLoading' />
                    <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("uploadDocFlow.AdvancedSettings")}</label>
                  </div>
                </Accordion.Header>
                <Accordion.Body className='p-3'>
                  <Form>
                    <div className='mb-3'>
                      <label className='fw-bold mb-2'>{t("uploadDocFlow.SigningSettings")}</label>
                      <Form.Check type='checkbox' label={t("uploadDocFlow.allowReject")} {...register("IsAllowDeclined")} />
                      <Form.Check type='checkbox' label={t("uploadDocFlow.CanAddComment")} {...register("CanAddComment")} />
                      {/* <Form.Check type='checkbox' label={t("uploadDocFlow.CanAddSupportingDocument")} {...register("CanAddSupportingDocument")} /> */}
                      <Form.Check type='checkbox' label={t("uploadDocFlow.allowDownload")} {...register("IsAllowDownload")} />
                      <Form.Check type='checkbox' label={t("uploadDocFlow.allowTransfer")} {...register("IsAllowTransfer")} />
                      <Form.Check type='checkbox' label={t("uploadDocFlow.CanDownloadAuditTrail")} {...register("CanDownloadAuditTrail")} />
                    </div>
                    <hr />
                    <div className='mb-3'>
                      <label className='fw-bold mb-2'>{t("uploadDocFlow.SendingSettings")}</label>
                      <Form.Check type='checkbox' label={t("uploadDocFlow.attachCompletedDocuments")} {...register("IsAttachCompletedDoc")} />
                      <Form.Check type='checkbox' label={t("uploadDocFlow.attachAuditTrial")} {...register("IsAttachAuditTrialDoc")} />
                    </div>

                    <hr />
                    <div className='mb-3'>
                      <label className='fw-bold mb-2'>{t("uploadDocFlow.ReminderSettings")}</label>
                      {/* <Form.Check type='checkbox' className='mb-2' label={t("uploadDocFlow.remindBeforeExpiry")} {...register("SetReminderBefore1Day")} />

                      <Form.Group className=' d-flex align-items-center gap-3 mb-2' controlId='formAutoReminderInDays'>
                        <Form.Label>{t("uploadDocFlow.autoReminder")}</Form.Label>
                        <Form.Control style={{ width: "15%" }} type='number' {...register("AutoReminderInDays")} className='form-control-sm' />
                      </Form.Group> */}

                      <Form.Group className='d-flex align-items-center gap-3 mb-3' controlId='formSetExpiryDate'>
                        <Form.Label>{t("uploadDocFlow.signatureExpires")}</Form.Label>
                        <Form.Control type='date' style={{ width: "25%" }} min={currentDateExpiry} {...register("SetExpiryDate")} />
                      </Form.Group>
                    </div>

                    <hr />
                    <label className='fw-bold mb-2'>{t("uploadDocFlow.archivingDocuments")}</label>

                    {/* Display the selected folder name */}
                    {selectedFolderName ? (
                      <p>
                        {t("uploadDocFlow.documentGoesTo")}{" "}
                        <strong>
                          {selectedFolderName} {t("uploadDocFlow.Sfolders")}{" "}
                        </strong>
                        {t("uploadDocFlow.afterSigning")}
                      </p>
                    ) : (
                      <p>{t("uploadDocFlow.noFolderSelected")}</p>
                    )}

                    {/* Button to open Folder Selection Offcanvas */}
                    <Button onClick={() => setIsCanvasVisible(true)} className='selectFolder'>
                      {t("uploadDocFlow.SelectFolder")}
                    </Button>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className=' fixed-button-container'>
            <Button className='backBtn' id='hide_part_one' onClick={handleBack}>
              {t("uploadDocFlow.back")}
            </Button>
            <Button className='Continue-btn' id='hide_part_one' onClick={openConfirmationModal}>
              {t("uploadDocFlow.sendAndSign")}
            </Button>
          </div>

          {/* file delete modal */}
          <Modal keyboard={false} backdrop='static' show={fileModal} onHide={handleFileCloseModal} dialogClassName='custom-modal-width'>
            <Modal.Header>
              <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteFile")}</Modal.Body>
            <Modal.Footer>
              <Button variant='' onClick={handleFileCloseModal} className='modalCancelButton'>
                {t("uploadDocFlow.cancel")}
              </Button>
              <Button variant='' className='applyBtn' onClick={() => handleFileDelete(deletingSupportingDocID, deletingIndex)}>
                {t("uploadDocFlow.delete")}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal keyboard={false} backdrop='static' show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t("uploadDocFlow.viewFile")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isSupported ? (
                <iframe title='Document Viewer' src={documentPath} alt='cant preview file' width='100%' height='500' className='overflow-x-none'></iframe>
              ) : (
                <p>
                  {t("uploadDocFlow.previewfile")}
                  <Button className='applyBtn' variant='none' onClick={() => handleFileDownload(documentPath)}>
                    <AiOutlineDownload size={18} />
                  </Button>
                </p>
              )}
            </Modal.Body>
          </Modal>

          {/* Confirmation Modal */}
          <Modal keyboard={false} backdrop='static' show={isConfirmationModalOpen} onHide={closeConfirmationModal} dialogClassName='custom-modal-width'>
            <Modal.Header>
              <Modal.Title>{t("uploadDocFlow.confirmSend")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.proceed")}</Modal.Body>
            <Modal.Footer>
              <Button onClick={closeConfirmationModal} variant='' className='modalCancelButton'>
                {t("uploadDocFlow.no")}
              </Button>
              <Button onClick={handleYesButton} className='px-4 applyBtn'>
                {t("uploadDocFlow.yes")}
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Success Modal */}
          <Modal keyboard={false} backdrop='static' show={isSuccessModalOpen} onHide={closeSuccessModal} dialogClassName='custom-modal-width'>
            <Modal.Header>
              <Modal.Title>{t("uploadDocFlow.requestSent")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.requestProceed")}</Modal.Body>
            <Modal.Footer>
              <Button onClick={closeSuccessModal} className='applyBtn'>
                {t("uploadDocFlow.close")}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal keyboard={false} backdrop='static' show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
            <Modal.Header>
              <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteComment")}</Modal.Body>
            <Modal.Footer>
              <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
                {t("uploadDocFlow.cancel")}
              </Button>
              <Button variant='none' className='applyBtn' onClick={() => handleDeleteComment(deletingCommentId, commentToDeleteIndex)}>
                {t("uploadDocFlow.delete")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <FolderSelection show={isCanvasVisible} onClose={() => setIsCanvasVisible(false)} onSelectFolder={handleSelectFolder} token={token} />
    </Container>
  );
};

export default Thirdstep;
