import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { Button, Form, Accordion, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useUser } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import emailIcon from "../../assets/images/sms-edit.svg";
import ReactSwitch from "react-switch";
import Loader from "../Loader/Loader";

function Delegation() {
  const { t } = useTranslation();
  const refreshToken = process.env.REACT_APP_STATIC_TOKEN;

  const [isLoading, setIsLoading] = useState(false);

  const { user, userAccess } = useUser();
  const [countryCodes, setCountryCodes] = useState([]);
  const [delegateData, setDelegateData] = useState(null);
  const [activeDelegate, setActiveDelegate] = useState(false);
  const [buttonHide, setButtonHide] = useState(false);
  const token = sessionStorage.getItem("token");

  const [formData, setFormData] = useState({
    DelegatedFromEmails: "",
    DelegatedToEmail: "",
    DelegatedToCountryCode: "",
    DelegatedToPhone: "",
    StartDate: "",
    EndDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setButtonHide(true);
  };

  const handleToggleChange = (isChecked) => {
    setActiveDelegate(isChecked);
    ActivateDelegation(isChecked);
  };

  const CreateDelegation = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/v1.0/api/CreateDelegation", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("delegation.delegationCreated"));
        setFormData({
          ...formData,
          DelegatedFromEmails: user.email, // Set DelegatedFromEmails to user's email
          DelegatedToEmail: "",
          DelegatedToCountryCode: "",
          DelegatedToPhone: "",
          StartDate: "",
          EndDate: "",
        });
        setButtonHide(false);
        getDelegation();
      } else {
        toast.error(t("delegation.failedcreated"));
      }
    } catch (error) {
      console.error("Error creating delegation.");
    }
  };

  const ActivateDelegation = async (isChecked) => {
    try {
      const response = await axios.put(
        "/v1.0/api/ActivateDelegation",
        {
          SignDelegateID: delegateData.signDelegateID,
          IsActive: isChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.response === true) {
        toast.success(t("delegation.delegationActivated"));
        getDelegation();
      } else if (response.data.response === false) {
        toast.success(t("delegation.SuccessFullyDeactivate"));
        getDelegation();
      } else {
        toast.error(t("delegation.errorActivate"));
      }
    } catch (error) {
      console.error(t("delegation.errorActivate"));
      setIsLoading(false);
    }
  };

  const getDelegation = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetDelegate", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const userInfo = response.data.response;
        setDelegateData(userInfo);
        setFormData({
          ...formData,
          DelegatedFromEmails: userInfo.delegatedFromEmails,
          DelegatedToEmail: userInfo.delegatedToEmail,
          DelegatedToCountryCode: userInfo.delegatedToCountryCode,
          DelegatedToPhone: userInfo.delegatedToPhone,
          StartDate: userInfo.startDate,
          EndDate: userInfo.endDate,
        });
        setActiveDelegate(userInfo.isActive);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const response = await axios.put(
        "/v1.0/api/UpdateDelegation",
        {
          ...formData,
          SignDelegateID: delegateData.signDelegateID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("delegation.successUpdate"));
      }
      setButtonHide(false);
      getDelegation();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Initialize DelegatedFromEmails with user's email on component mount
    setFormData((prevFormData) => ({
      ...prevFormData,
      DelegatedFromEmails: user.email,
    }));
  }, [user.email]);

  useEffect(() => {
    getDelegation();
  }, [userAccess.remainingDays]);

  useEffect(() => {
    const countryCode = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/Account/GetCountryCodes", {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        if (response.data.statusCode === "1") {
          const countryCodeList = response.data.response;
          setCountryCodes(countryCodeList);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    countryCode();
  }, []);

  const {
    formState: { errors },
  } = useForm();

  const handleCancel = () => {
    setFormData({
      DelegatedFromEmails: delegateData.delegatedFromEmails,
      DelegatedToEmail: delegateData.delegatedToEmail,
      DelegatedToCountryCode: delegateData.delegatedToCountryCode,
      DelegatedToPhone: delegateData.delegatedToPhone,
      StartDate: delegateData.startDate,
      EndDate: delegateData.endDate,
    });
    setButtonHide(false);
  };

  const handleClearAndCancel = () => {
    setFormData({
      ...formData,
      DelegatedFromEmails: user.email, // Set DelegatedFromEmails to user's email
      DelegatedToEmail: "",
      DelegatedToCountryCode: "",
      DelegatedToPhone: "",
      StartDate: "",
      EndDate: "",
    });
    setButtonHide(false);
  };

  const currentDateExpiry = new Date().toISOString().split("T")[0];

  return (
    <div className='container my-3 p-0'>
      {userAccess.remainingDays <= 0 ? (
        <></>
      ) : (
        <>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='2'>
              <Accordion.Header>
                <div className='d-flex align-items-center gap-3'>
                  <img src={emailIcon} alt='IconLoading' />
                  <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("userProfile.Delegation")}</label>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {isLoading && (
                  <div className='loader-overlay'>
                    <Loader />
                  </div>
                )}

                <Form className='my-3' onSubmit={CreateDelegation}>
                  <Row className='justify-content-end m-0'>
                    <Col md={2}>
                      <Form.Group controlId='StartDate'>
                        <Form.Label>{t("userProfile.StartDate")}</Form.Label>
                        <Form.Control type='date' name='StartDate' min={currentDateExpiry} value={formData.StartDate ? formData.StartDate.slice(0, 10) : ""} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId='EndDate'>
                        <Form.Label>{t("userProfile.EndDate")}</Form.Label>
                        <Form.Control type='date' name='EndDate' min={currentDateExpiry} value={formData.EndDate ? formData.EndDate.slice(0, 10) : ""} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Group controlId='DelegatedToEmail'>
                        <Form.Label>{t("userProfile.DelegatedEmail")}</Form.Label>
                        <Form.Control type='email' placeholder="Enter user's email" name='DelegatedToEmail' value={formData.DelegatedToEmail} onChange={handleChange} required />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <div>
                        <Form.Label className='form-label'>{t("logScreens.mobileNumber")}</Form.Label>
                        <div className='input-group'>
                          <Form.Select
                            as='select'
                            value={formData.DelegatedToCountryCode}
                            onChange={(e) => {
                              setFormData({ ...formData, DelegatedToCountryCode: e.target.value });
                              console.log("Updated formData:", { DelegatedToCountryCode: e.target.value });
                            }}
                            className={`form-control ${errors && errors.CountryCode ? "error-border" : ""}`}
                            style={{ width: "15%" }}
                          >
                            <option value='' disabled>
                              {t("logScreens.Codes")}
                            </option>
                            {countryCodes.map((country) => (
                              <option key={country.lookupID} value={country.lookupID}>
                                {country.lookupID}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            type='text'
                            name='DelegatedToPhone'
                            placeholder={t("logScreens.mobileNumber")}
                            value={formData.DelegatedToPhone}
                            onChange={handleChange}
                            pattern='[0-9]{11,15}'
                            minLength={10}
                            maxLength={15}
                            style={{ width: "calc(80% - 40px)" }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div style={{ display: "flex", gap: "10px", marginTop: "33px" }}>
                        <span className='password-protected'>{t("userProfile.ActivateDelegation")}</span>
                        <ReactSwitch
                          checked={activeDelegate}
                          onChange={handleToggleChange}
                          onColor='#0c9ad7'
                          onHandleColor='#ffffff'
                          handleDiameter={15}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={50}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* New fields for StartDate and EndDate */}

                  {delegateData === null
                    ? buttonHide && (
                        <div div className='d-flex gap-2 justify-content-start my-2'>
                          <Button variant='none' className='ImageDoneBtn' type='submit'>
                            {t("userProfile.save")}
                          </Button>
                          <Button variant='none' className='ImageCancelBtn' onClick={handleClearAndCancel}>
                            {t("userProfile.cancel")}
                          </Button>
                        </div>
                      )
                    : buttonHide && (
                        <div div className='d-flex gap-2 justify-content-start my-2'>
                          <Button variant='none' className='ImageDoneBtn' onClick={handleUpdate}>
                            {t("userProfile.update")}
                          </Button>
                          <Button variant='none' className='ImageCancelBtn' onClick={handleCancel}>
                            {t("userProfile.cancel")}
                          </Button>
                        </div>
                      )}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </div>
  );
}

export default Delegation;

// import React, { useState, useEffect } from "react";
// import axios from "../../Api/api";
// import { staticToken } from "../../Token/token";
// import { toast } from "react-toastify";
// import { Button, Form, Accordion, Row, Col } from "react-bootstrap";
// import { useForm } from "react-hook-form";
// import { useUser } from "../../Context/UserContext";
// import { useTranslation } from "react-i18next";
// import emailIcon from "../../assets/images/sms-edit.svg";
// import ReactSwitch from "react-switch";
// import Loader from "../Loader/Loader";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// function Delegation() {
//   const { t } = useTranslation();
//   const refreshToken = staticToken;
//   const [isLoading, setIsLoading] = useState(false);
//   const { user, userAccess } = useUser();
//   const [countryCodes, setCountryCodes] = useState([]);
//   const [delegateData, setDelegateData] = useState(null);
//   const [activeDelegate, setActiveDelegate] = useState(false);
//   const [buttonHide, setButtonHide] = useState(false);
//   const token = sessionStorage.getItem("token");

//   const [formData, setFormData] = useState({
//     DelegatedFromEmails: "",
//     DelegatedToEmail: "",
//     DelegatedToCountryCode: "",
//     DelegatedToPhone: "",
//     StartDate: "",
//     EndDate: "",
//   });

//   const [dateRange, setDateRange] = useState([null, null]);
//   const [startDate, endDate] = dateRange;

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     setButtonHide(true);
//   };

//   const handleDateRangeChange = (update) => {
//     setDateRange(update);
//     setFormData({
//       ...formData,
//       StartDate: update[0] ? update[0].toISOString().split("T")[0] : "",
//       EndDate: update[1] ? update[1].toISOString().split("T")[0] : "",
//     });
//     setButtonHide(true);
//   };

//   const handleToggleChange = (isChecked) => {
//     setActiveDelegate(isChecked);
//     ActivateDelegation(isChecked);
//   };

//   const CreateDelegation = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post("/v1.0/api/CreateDelegation", formData, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (response.data.statusCode === "1") {
//         toast.success(t("delegation.delegationCreated"));
//         setFormData({
//           ...formData,
//           DelegatedFromEmails: user.email,
//           DelegatedToEmail: "",
//           DelegatedToCountryCode: "",
//           DelegatedToPhone: "",
//           StartDate: "",
//           EndDate: "",
//         });
//         setDateRange([null, null]);
//         setButtonHide(false);
//         getDelegation();
//       } else {
//         toast.error(t("delegation.failedcreated"));
//       }
//     } catch (error) {
//       toast.error(t("delegation.failedcreated"));
//     }
//   };

//   const ActivateDelegation = async (isChecked) => {
//     try {
//       const response = await axios.put(
//         "/v1.0/api/ActivateDelegation",
//         {
//           SignDelegateID: delegateData.signDelegateID,
//           IsActive: isChecked,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.data.response === true) {
//         toast.success(t("delegation.delegationActivated"));
//         getDelegation();
//       } else if (response.data.response === false) {
//         toast.success(t("delegation.SuccessFullyDeactivate"));
//         getDelegation();
//       } else {
//         toast.error(t("delegation.errorActivate"));
//       }
//     } catch (error) {
//       toast.error(t("delegation.errorActivate"));
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const getDelegation = async () => {
//     try {
//       setIsLoading(true);
//       const response = await axios.get("/v1.0/api/GetDelegate", {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (response.data.statusCode === "1") {
//         const userInfo = response.data.response;
//         setDelegateData(userInfo);
//         setFormData({
//           ...formData,
//           DelegatedFromEmails: userInfo.delegatedFromEmails,
//           DelegatedToEmail: userInfo.delegatedToEmail,
//           DelegatedToCountryCode: userInfo.delegatedToCountryCode,
//           DelegatedToPhone: userInfo.delegatedToPhone,
//           StartDate: userInfo.startDate,
//           EndDate: userInfo.endDate,
//         });
//         setDateRange([userInfo.startDate ? new Date(userInfo.startDate) : null, userInfo.endDate ? new Date(userInfo.endDate) : null]);
//         setActiveDelegate(userInfo.isActive);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleUpdate = async () => {
//     try {
//       setIsLoading(true);
//       const response = await axios.put(
//         "/v1.0/api/UpdateDelegation",
//         {
//           ...formData,
//           SignDelegateID: delegateData.signDelegateID,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (response.data.statusCode === "1") {
//         toast.success(t("delegation.successUpdate"));
//         getDelegation();
//       } else {
//         toast.error(t("delegation.failedcreated"));
//       }
//       setButtonHide(false);
//     } catch (error) {
//       toast.error(t("delegation.failedcreated"));
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       DelegatedFromEmails: user.email,
//     }));
//   }, [user.email]);

//   useEffect(() => {
//     getDelegation();
//   }, [userAccess.remainingDays]);

//   useEffect(() => {
//     const countryCode = async () => {
//       try {
//         setIsLoading(true);
//         const response = await axios.get("/Account/GetCountryCodes", {
//           headers: {
//             Authorization: `Bearer ${refreshToken}`,
//           },
//         });
//         if (response.data.statusCode === "1") {
//           setCountryCodes(response.data.response);
//         }
//       } catch (error) {
//         console.error(error);
//       } finally {
//         setIsLoading(false);
//       }
//     };
//     countryCode();
//   }, []);

//   return (
//     <div className='container my-3 p-0'>
//       {userAccess.remainingDays <= 0 ? (
//         <></>
//       ) : (
//         <>
//           <Accordion defaultActiveKey='0'>
//             <Accordion.Item eventKey='2'>
//               <Accordion.Header>
//                 <div className='d-flex align-items-center gap-3'>
//                   <img src={emailIcon} alt='IconLoading' />
//                   <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("userProfile.Delegation")}</label>
//                 </div>
//               </Accordion.Header>
//               <Accordion.Body>
//                 {isLoading && <Loader />}

//                 <Form className='my-3' onSubmit={CreateDelegation}>
//                   <Row className='justify-content-end m-0'>
//                     <Col md={4}>
//                       <Form.Group controlId='dateRange'>
//                         <Form.Label>{t("userProfile.StartDate")}</Form.Label>
//                         <DatePicker
//                           selected={startDate}
//                           onChange={(update) => handleDateRangeChange(update)}
//                           startDate={startDate}
//                           endDate={endDate}
//                           selectsRange
//                           minDate={new Date()}
//                           dateFormat='yyyy-MM-dd'
//                           isClearable
//                           placeholderText='Select start and end dates'
//                           className='form-control'
//                         />
//                       </Form.Group>
//                     </Col>
//                   </Row>

//                   <Row>
//                     <Col md={4}>
//                       <Form.Group controlId='DelegatedToEmail'>
//                         <Form.Label>{t("userProfile.DelegatedEmail")}</Form.Label>
//                         <Form.Control type='email' placeholder="Enter user's email" name='DelegatedToEmail' value={formData.DelegatedToEmail} onChange={handleChange} required />
//                       </Form.Group>
//                     </Col>

//                     <Col md={4}>
//                       <Form.Label>{t("logScreens.mobileNumber")}</Form.Label>
//                       <div className='input-group'>
//                         <Form.Select
//                           value={formData.DelegatedToCountryCode}
//                           onChange={(e) => setFormData({ ...formData, DelegatedToCountryCode: e.target.value })}
//                           className='form-control'
//                           style={{ width: "15%" }}
//                         >
//                           <option value='' disabled>
//                             {t("logScreens.Codes")}
//                           </option>
//                           {countryCodes.map((country) => (
//                             <option key={country.lookupID} value={country.lookupID}>
//                               {country.lookupID}
//                             </option>
//                           ))}
//                         </Form.Select>
//                         <Form.Control
//                           type='text'
//                           name='DelegatedToPhone'
//                           placeholder={t("logScreens.mobileNumber")}
//                           value={formData.DelegatedToPhone}
//                           onChange={handleChange}
//                           pattern='[0-9]{11,15}'
//                           minLength={10}
//                           maxLength={15}
//                           style={{ width: "calc(80% - 40px)" }}
//                         />
//                       </div>
//                     </Col>

//                     <Col md={3}>
//                       <div style={{ display: "flex", gap: "10px", marginTop: "33px" }}>
//                         <span>{t("userProfile.ActivateDelegation")}</span>
//                         <ReactSwitch
//                           checked={activeDelegate}
//                           onChange={handleToggleChange}
//                           onColor='#0c9ad7'
//                           onHandleColor='#ffffff'
//                           handleDiameter={15}
//                           uncheckedIcon={false}
//                           checkedIcon={false}
//                           height={20}
//                           width={50}
//                         />
//                       </div>
//                     </Col>
//                   </Row>

//                   {delegateData === null
//                     ? buttonHide && (
//                         <div className='d-flex gap-2 justify-content-start my-2'>
//                           <Button variant='none' className='ImageDoneBtn' type='submit'>
//                             {t("userProfile.save")}
//                           </Button>
//                           <Button variant='none' className='ImageCancelBtn' onClick={() => setButtonHide(false)}>
//                             {t("userProfile.cancel")}
//                           </Button>
//                         </div>
//                       )
//                     : buttonHide && (
//                         <div className='d-flex gap-2 justify-content-start my-2'>
//                           <Button variant='none' className='ImageDoneBtn' onClick={handleUpdate}>
//                             {t("userProfile.update")}
//                           </Button>
//                           <Button variant='none' className='ImageCancelBtn' onClick={() => setButtonHide(false)}>
//                             {t("userProfile.cancel")}
//                           </Button>
//                         </div>
//                       )}
//                 </Form>
//               </Accordion.Body>
//             </Accordion.Item>
//           </Accordion>
//         </>
//       )}
//     </div>
//   );
// }

// export default Delegation;
