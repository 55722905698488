import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import noDocImg from "../../assets/images/NoDocuments (1).png";
import { useUser } from "../../Context/UserContext";
import CustomToggle from "../CustomDots/CustomToggle";
import inactiveToggle from "../../assets/images/InactiveIcon.svg";
import activeIcon from "../../assets/images/Active.svg";
import EditSquareIcon from "../../assets/images/EditSquare.png";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserLisence from "../UserLisence/UserLisence";

const EmailTemplates = () => {
  const { t } = useTranslation();
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const { user, userAccess } = useUser();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [templateToToggle, setTemplateToToggle] = useState(null);
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");

  const fetchEmailTemplates = async () => {
    if (!user.AccountID) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetAccountEmailTemplates?AccountID=${user.AccountID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusCode === "1") {
        setEmailTemplates(response.data.response);
        setTotalPages(Math.ceil(response.data.response.length / rowsPerPage));
      } else {
        setEmailTemplates([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailTemplates();
  }, [user.AccountID, token, userAccess.remainingDays]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  // Toggle status between active and inactive
  const handleToggleStatus = async () => {
    if (!templateToToggle) return;

    const { emailTemplateID, isActive, templateType } = templateToToggle;
    setIsLoading(true);
    try {
      const response = await axios.patch(
        `/v1.0/api/UpdateAccountEmailTemplateStatus`,
        { EmailTemplateID: emailTemplateID, IsActive: !isActive, AccountID: user.AccountID, TemplateType: templateType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === "1") {
        toast.success(t("emailTemplates.emailUpdated"));
        fetchEmailTemplates();
      } else if (response.data.statusCode === "10") {
        toast.info(t("emailTemplates.DeactiveFirst"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setShowConfirmModal(false);
    }
  };

  // Open confirmation modal
  const handleConfirmToggle = (template) => {
    setTemplateToToggle(template);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleCreateEmail = () => {
    navigate("/create-email-template");
  };

  const handleEditTemplate = (template) => {
    navigate("/create-email-template", { state: { template } });
  };

  const columns = [
    { label: t("tablesLabel.subject"), key: "subject" },
    { label: t("tablesLabel.tempType"), key: "templateType" },
    { label: t("tablesLabel.createdby"), key: "createdBy" },
    { label: t("tablesLabel.status"), key: "isActive" },
    { label: "", key: "actions" },
  ];

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  return (
    <>
      {userAccess.remainingDays <= 0 ? (
        <UserLisence />
      ) : (
        <>
          {(user.AccountType === "B" && user.RoleID === 1) || userAccess.remainingDays >= 0 ? (
            <>
              {userAccess.macAI === true ? (
                <>
                  <Grid className='container mt-3'>
                    {isLoading && (
                      <div className='loader-overlay'>
                        <Loader />
                      </div>
                    )}
                    <button onClick={handleCreateEmail} className='templateButton'>
                      {t("emailTemplates.createEmail")}
                    </button>

                    <div className='py-3 p-3 userProfile-form mt-3'>
                      <div className='d-flex align-items-center justify-content-between p-2'>
                        <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("emailTemplates.emailTemplates")}</label>
                      </div>

                      <TableContainer component={Paper} style={{ height: "60vh" }}>
                        <Table style={{ border: "none" }}>
                          <TableHead className='customTableHead'>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell key={column.label} style={{ backgroundColor: "#f0f3f4" }} className='TableHeaderCell'>
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {emailTemplates.length === 0 && (
                              <TableRow>
                                <td colSpan='6' className='p-5 text-center'>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <img alt='No documents' src={noDocImg} style={{ height: "120px", width: "180px" }} />
                                    {t("emailTemplates.noEmailFound")}
                                  </div>
                                </td>
                              </TableRow>
                            )}

                            {emailTemplates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((template) => (
                              <TableRow key={template.emailTemplateID}>
                                <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                                  {template.subject}
                                </TableCell>
                                <TableCell className='TableCell'>{template.templateType}</TableCell>
                                <TableCell className='TableCell'>{template.createdBy}</TableCell>
                                <TableCell className='TableCell'>
                                  <label
                                    style={{
                                      padding: "2px 12px",
                                      borderRadius: "5px",
                                      backgroundColor: template.isActive ? "#00cc4533" : "#eeeeee",
                                      color: "black",
                                    }}
                                  >
                                    {template.isActive ? "Active" : "Inactive"}
                                  </label>
                                </TableCell>
                                <TableCell className='py-0'>
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleConfirmToggle(template)}>
                                        <img src={template.isActive ? inactiveToggle : activeIcon} alt='iconLoading' />
                                        {template.isActive ? t("emailTemplates.inactive") : t("emailTemplates.active")}
                                      </Dropdown.Item>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleEditTemplate(template)}>
                                        <img src={EditSquareIcon} alt='iconLoading' />
                                        {t("emailTemplates.editTemplate")}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                        <div>
                          {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, emailTemplates.length)} {t("emailTemplates.templateof")} {emailTemplates.length} {t("emailTemplates.template")}
                        </div>
                        <Stack spacing={2}>
                          <Pagination
                            count={totalPages}
                            variant='outlined'
                            shape='rounded'
                            page={page + 1}
                            onChange={(event, newPage) => handleChangePage(newPage - 1)}
                            sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
                          />
                        </Stack>
                      </div>
                    </div>

                    {/* Confirmation Modal for Toggling Status */}
                    <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} backdrop='static' keyboar={false} dialogClassName='custom-modal-width'>
                      <Modal.Header>
                        <Modal.Title>{t("emailTemplates.Confirmation")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className='p-3'>{templateToToggle && templateToToggle.isActive ? t("emailTemplates.sureDeactivate") : t("emailTemplates.sureActive")}</Modal.Body>
                      <Modal.Footer className='d-flex justify-content-end gap-5'>
                        <Button variant='secondary' className='modalCancelButton' onClick={handleCloseConfirmModal}>
                          {t("dashboardCardStatuses.cancel")}
                        </Button>
                        <Button variant='primary' className='applyBtn' onClick={handleToggleStatus}>
                          {t("dashboardCardStatuses.confirm")}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default EmailTemplates;
